import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OperatorFunction, Observable, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbDate, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ReportService } from '../../services/report.service';
import { FODItem } from '../../model/common';
import { SpinnerService } from '../../services/spinner.service';
import { createPhoneNumberValidator } from '../../validators/phonenumber.validator';


@Component({
  selector: 'app-base-report',
  templateUrl: './base-report.component.html',
  styleUrl: './base-report.component.css'
})
export class BaseReportComponent implements OnInit, AfterViewInit, OnChanges {
  // submitareport, editareport, viewreport, printreport are valid values
  @Input() reportMode: any = null;

  @Input() reportNumber: any = null;

  @Input() foundFODReport: any = null;

  statusMessage: string = '';

  @ViewChild('airportname') airportNameTypeaheadInput: ElementRef;
  @ViewChild('foddiscoverydate') fodDiscoveryDateInput: ElementRef;

  @Output() public onUpdate: EventEmitter<any> = new EventEmitter();

  fodTypeChoices: any[] = [];

  detectionTypeChoices: any[] = [];
  filteredDetectionTypeChoices: any[] = [];

  airportsArr: any[] = [];
  airportsTypeaheadArr: any[] = [];

  showPhoneMask: boolean = false;
  reporterPhoneInputValid: boolean = false;

  fodItemsArr: FODItem[];

  //10-19-21
  deletedFODItemsArr: FODItem[];

  timeFrameArr = [
    { id: 1, name: '00:00 - 04:00' },
    { id: 2, name: '04:00 - 08:00' },
    { id: 3, name: '08:00 - 12:00' },
    { id: 4, name: '12:00 - 16:00' },
    { id: 5, name: '16:00 - 20:00' },
    { id: 6, name: '20:00 - 24:00' }
  ];

  baseReportForm: FormGroup;

  formButtonText: string = 'Submit';

  constructor(private fb: FormBuilder, public reportService: ReportService, private spinnerService: SpinnerService, public router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['foundFODReport']) {
      this.foundFODReport = changes['foundFODReport'].currentValue;
      if (this.foundFODReport) {
        this.fodItemsArr = this.foundFODReport['FODItems'];

        switch (this.reportMode) {
          case 'viewreport':
          case 'printreport': {
            this.initBaseReportFormInputs([{ func: this.initPrintReportFormMode, args: [] }]);
            break;
          }
          case 'updatereport': {
            this.initBaseReportFormInputs([{ func: this.initUpdateReportFormMode, args: [] }]);
            break;
          }
          default: {
            console.log('ngOnchanges unknown reportmode')
            break;
          }
        }

      }

    }

  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.initBaseReport();

    switch (this.reportMode) {
      case 'submitareport': {
        this.fodItemsArr = [
          {
            FODID: 0,
            ReportID: 0,
            LocationID: 0,
            SizeID: 0,
            ObjectTypeID: 0,
            ObjectTypeOtherText: '',
            DesignatorID: 0,
            DesignatorDescription: '',
            Remark: '',
            Images: [],
            LocationCode: '',
            SizeCode: '',
            ObjectTypeCode: '',
            DesignatorCode: '',
            markedForDelete: 0
          }
        ];
        //this.initBaseReportFormInputs();
        // 10-14-21
        this.initBaseReportFormInputs([{ func: this.populateDetectionTypeInput, args: ['SINGLE'] }]);
        this.baseReportForm.get('FODItems')?.setValue(this.fodItemsArr);
        this.baseReportForm.get('TimeFrameID')?.setValue(1);
        this.baseReportForm.get('Time')?.setValue('00:00 - 04:00');
        
        break;
      }
      case 'editareport': {
        break;
      }
      case 'viewreport': {
        break;
      }
      case 'updatereport': {
        // 10-19-21
        this.deletedFODItemsArr = [];
        this.formButtonText = 'Save Report';
        break;
      }
      case 'printreport': {
        // this.initBaseReportFormInputs();
        break;
      }
      default: {
        console.log('Unknown report mode')
        break;
      }

    }

    //    this.initBaseReportFormInputs();
  }

  // Report Form related functions

  sendFODReportObjectToParentComponent(obj: any) {
    this.onUpdate.emit(obj);
  }

  initUpdateReportFormMode() {
    Object.keys(this.baseReportForm.controls).forEach((key) => {

      if (key === 'FODItems') {
        this.fodItemsArr = this.foundFODReport.FODItems;
      }
      if (key === 'CollectionTypeCode') {
        this.populateDetectionTypeInput(this.foundFODReport[key]);
      }

      if (key === 'AirportID') {
        let foundAirport = this.airportsArr.find((elem) => {
          let searchTerm = this.foundFODReport[key];

          if (elem.ID === searchTerm) {
            return true;
          }
          return false;
        });

        let formattedAirportName = `(${foundAirport.ARPID}) ${foundAirport.AirportName}`;
        this.foundFODReport['AirportName'] = formattedAirportName;
        this.airportNameTypeaheadInput.nativeElement.value = this.foundFODReport['AirportName'];
        this.airportNameTypeaheadInput.nativeElement.disabled = true;
      }

      // if (key === 'AirportName') {
      //   let foundAirportName = this.airportsTypeaheadArr.find((elem) => {
      //     let searchTerm = this.foundFODReport[key];

      //     if (elem.indexOf(searchTerm) != -1) {
      //       return true;
      //     }
      //     return false;
      //   })

      //   this.foundFODReport[key] = foundAirportName;
      //   this.airportNameTypeaheadInput.nativeElement.value = this.foundFODReport[key];
      //   this.airportNameTypeaheadInput.nativeElement.disabled = true;
      // }

      if (key === 'Date') {
        this.fodDiscoveryDateInput.nativeElement.value = this.foundFODReport[key];
      }

      this.baseReportForm.get(key)?.setValue(this.foundFODReport[key]);
    });
  }

  initPrintReportFormMode() {
    Object.keys(this.baseReportForm.controls).forEach((key) => {
      if (key === 'FODItems') {
        this.fodItemsArr = this.foundFODReport.FODItems;
      }
      if (key === 'CollectionTypeCode') {
        this.populateDetectionTypeInput(this.foundFODReport[key]);
      }

      if (key === 'AirportID') {
        let foundAirport = this.airportsArr.find((elem) => {
          let searchTerm = this.foundFODReport[key];

          if (elem.ID === searchTerm) {
            return true;
          }
          return false;
        });

        let formattedAirportName = `(${foundAirport.ARPID}) ${foundAirport.AirportName}`;
        this.foundFODReport['AirportName'] = formattedAirportName;
        this.airportNameTypeaheadInput.nativeElement.value = this.foundFODReport['AirportName'];
        this.airportNameTypeaheadInput.nativeElement.disabled = true;
      }
      /*
      if (key === 'AirportName') {
        let foundAirportName = this.airportsTypeaheadArr.find((elem) => {
          let searchTerm = this.foundFODReport[key];

          if (elem.indexOf(searchTerm) !== -1) {
            return true;
          }
          return false;
        })

        this.foundFODReport[key] = foundAirportName;
        this.airportNameTypeaheadInput.nativeElement.value = this.foundFODReport[key];
        this.airportNameTypeaheadInput.nativeElement.disabled = true;
      }
      */
      if (key === 'Date') {
        this.fodDiscoveryDateInput.nativeElement.value = this.foundFODReport[key];
        this.fodDiscoveryDateInput.nativeElement.disabled = true;
      }

      this.baseReportForm.get(key)?.setValue(this.foundFODReport[key]);
      this.baseReportForm.get(key)?.disable();
    });

  }

  initBaseReportFormInputs(arrayOfCallbackFuncs: any[]) {
    let detectionTypes$ = this.reportService.getDetectionTypes();
    let airports$ = this.reportService.getAirports();
    let collectionTypes$ = this.reportService.getFODItemCollectionTypes();

    const all = zip(
      airports$,
      detectionTypes$,
      collectionTypes$
    );

    this.spinnerService.show();
    all.subscribe(
      (resultsArr) => {
        let responseAirport = resultsArr[0];
        let responseDetectionTypes = resultsArr[1];
        let responseCollectionTypes = resultsArr[2];

        if (responseAirport.Success && responseAirport.Total > 0) {
          this.airportsArr = responseAirport.Result;

          let resArr = [];
          resArr = responseAirport.Result;

          this.airportsTypeaheadArr = resArr.map((elem: any) => {
            let ARPID = elem.ARPID;
            let AirportName = elem.AirportName;

            let outputStr = `(${ARPID}) ${AirportName}`;

            return outputStr;
          });
        }

        if (responseDetectionTypes.Success && responseDetectionTypes.Total > 0) {
          this.detectionTypeChoices = responseDetectionTypes.Result;
        }
        
        if (responseCollectionTypes.Success && responseCollectionTypes.Total > 0) {
          this.fodTypeChoices = responseCollectionTypes.Result;
        }

      },
      (err) => {
        console.log('ERROR', err);
        this.spinnerService.hide();
      },
      () => {
        console.log('Complete')
        //this.populateDetectionTypeInput('SINGLE');

        // 10-14-21
        for (let i = 0; i < arrayOfCallbackFuncs.length; i++) {
          let current = arrayOfCallbackFuncs[i];
          let currFunc = current.func;
          let args = current.args;

          currFunc.apply(this, args);
        }

        this.spinnerService.hide();
      }
    )
  }

  onSelectedAirport(evt: NgbTypeaheadSelectItemEvent) {
    let selectedItem = evt.item;
    let rightParenIndex = selectedItem.indexOf(")");

    let ARPID = selectedItem.slice(1, rightParenIndex).trim();
    let airportName = selectedItem.slice(rightParenIndex + 1).trim();
    this.setAirportNameIDFormValues(ARPID, airportName);
  }

  onSelectTimeFrame(evt: any) {
    let timeFrameText = evt.target.options[evt.target.options.selectedIndex].text;
    this.baseReportForm.get('Time')?.setValue(timeFrameText);
    this.baseReportForm.get('TimeFrameID')?.setValue(parseInt(evt.target.value));

  }

  onClickCalendar(dateObj: any) {
    dateObj.open();
  }

  onSelectDate(evt: NgbDate) {
    // YYYY-MM-DD
    let year = evt.year;
    let month = ('00' + evt.month).slice(-2);
    let day = ('00' + evt.day).slice(-2);
    let date = `${year}-${month}-${day}`;

    this.baseReportForm.get('Date')?.setValue(date);
  }

  setAirportNameIDFormValues(ARPID: string, airportName: string) {
    this.baseReportForm.get('AirportName')?.patchValue(airportName);

    let airportObj = this.airportsArr.filter((elem) => {
      if (elem.ARPID === ARPID) {
        return true;
      }
      return false;
    })

    this.baseReportForm.get('AirportID')?.patchValue(airportObj[0].Airport_ID);
  }

  searchAirport: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.airportsTypeaheadArr.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  initBaseReport(): void {
    this.baseReportForm = this.fb.group({
      ReportID: [0],
      ReportNumber: [''],
      Date: ['', Validators.required],
      Time: ['00:00 - 04:00'],
      AirportID: [''],
      AirportName: ['', Validators.required],
      DetectionTypeID: [0],
      CollectionTypeID: [0],
      ReporterName: ['', Validators.pattern(/^[a-zA-Z\s]*$/)],
      ReporterEmail: ['', Validators.email],
      ReporterPhone: ['', createPhoneNumberValidator()],
      ReporterTitle: ['', Validators.pattern(/^[a-zA-Z\s]*$/)],
      FODItems: [],
      TimeFrameID: 1,
      CollectionTypeCode: [''],
      DetectionTypeCode: ['']
    })
  }

  populateDetectionTypeInput(selectedFodType: string) {
    this.filteredDetectionTypeChoices = this.detectionTypeChoices.filter((elem) => {
      if (selectedFodType === elem.CollectionType) {
        return true;
      }
      return false;
    });

    if (this.reportMode === 'submitareport') {
      // Set default value of Detection Type based on FOD Type
      if (selectedFodType === 'SINGLE') {
        this.baseReportForm.get('DetectionTypeCode')?.setValue('SNR');
        this.baseReportForm.get('CollectionTypeCode')?.setValue('SINGLE');
      } else {
        this.baseReportForm.get('DetectionTypeCode')?.setValue('BNR');
        this.baseReportForm.get('CollectionTypeCode')?.setValue('BULK');

      }
    }
  }

  // Event Listeners
  onFocus(evt: any, elementName: string) {
    if (elementName === 'ReporterPhone') {
      this.showPhoneMask = true;
    }
  }

  onBlur(evt: any, elementName: string) {
    if (elementName === 'ReporterPhone') {
      this.showPhoneMask = false;
    }
  }

  onChangeFodType(evt: any) {
    this.populateDetectionTypeInput(evt.target.value);
  }

  // FOD Item related functions
  addFODItem() {
    let newItem: FODItem = {
      FODID: 0,
      ReportID: 0,
      LocationID: 0,
      SizeID: 0,
      ObjectTypeID: 0,
      ObjectTypeOtherText: '',
      DesignatorID: 0,
      DesignatorDescription: '',
      Remark: '',
      Images: [],
      LocationCode: '',
      SizeCode: '',
      ObjectTypeCode: '',
      DesignatorCode: '',
      markedForDelete: 0
    };

    this.fodItemsArr.push(newItem);
  }

  deleteFodItem(index: number) {
    // if in updatereport mode
    // Mark selected FOD Item for deletion
    if (this.reportMode === 'updatereport') {
      this.fodItemsArr[index]['markedForDelete'] = 1;

      let deletedItem = { ...this.fodItemsArr[index] };

      this.deletedFODItemsArr.push(deletedItem);
    }

    this.fodItemsArr.splice(index, 1);
  }

  handleFODItemUpdate(index: number, event: any) {
    let formControlName = event.formControlName;
    let formControlValue = event.formControlValue;

    let currentFODItem: any = this.fodItemsArr[index];

    currentFODItem[formControlName] = formControlValue;
  }

  onSaveOrUpdateReport() {
    if (this.reportMode === 'submitareport') {
      this.onSaveReport();
    } else {
      this.onUpdateReport();
    }
  }

  onUpdateReport() {

    Object.keys(this.baseReportForm.controls).forEach((key) => {
      if (key === 'FODItems') {
        // We want active FOD Items and Deleted FOD Items to be updated in the DB
        // this requires us to combine both
        let combinedArr: FODItem[] = [];
        combinedArr = this.fodItemsArr.concat(this.deletedFODItemsArr);
        this.baseReportForm.get(key)?.setValue(combinedArr);

      }

      if (key === 'CollectionTypeID') {
        let collectionChoiceObj = this.fodTypeChoices.filter((elem) => {
          let collectionTypeCode = this.baseReportForm.get('CollectionTypeCode')?.value;

          if (elem.CollectionType === collectionTypeCode) {
            return true;
          }

          return false;
        })

        this.baseReportForm.get(key)?.setValue(collectionChoiceObj[0].ID);
      }

      if (key === 'DetectionTypeID') {
        let detectionTypeChoiceObj = this.filteredDetectionTypeChoices.filter((elem) => {
          let detectionTypeCode = this.baseReportForm.get('DetectionTypeCode')?.value;

          if (elem.DetectionCode === detectionTypeCode) {
            return true;
          }
          return false;
        })
        this.baseReportForm.get(key)?.setValue(detectionTypeChoiceObj[0].ID);
      }
    });

    console.log(this.baseReportForm.value);

    this.spinnerService.show();
    this.reportService.updateReport(this.baseReportForm.value)
      .subscribe(
        (res) => {
          if (res.Success) {
            let reportNumber = res.Result.ReportNumber;
            let reportID = res.Result.ReportId;


            this.spinnerService.hide();

            this.router.navigate(['/confirmation'], {
              queryParams: {
                reportNumber,
                reportID
              }
            });
          } else {
            this.statusMessage = 'Error occurred while saving new FOD report';
            this.spinnerService.hide();
          }
        },
        (err) => {
          console.log('Edit Report ERROR', err);
          this.statusMessage = 'Error occurred while saving new FOD report';
          this.spinnerService.hide();
        }
      )
  }

  onSaveReport() {

    Object.keys(this.baseReportForm.controls).forEach((key) => {
      // 10-20-21
      if (key === 'Time') {
        if (this.baseReportForm.get(key)?.value === '') {
          this.baseReportForm.get(key)?.setValue(null);
        }
      }

      if (key === 'CollectionTypeID') {
        let collectionChoiceObj = this.fodTypeChoices.filter((elem) => {
          let collectionTypeCode = this.baseReportForm.get('CollectionTypeCode')?.value;

          if (elem.CollectionType === collectionTypeCode) {
            return true;
          }

          return false;
        })

        this.baseReportForm.get(key)?.setValue(collectionChoiceObj[0].ID);
      }

      if (key === 'DetectionTypeID') {
        let detectionTypeChoiceObj = this.filteredDetectionTypeChoices.filter((elem) => {
          let detectionTypeCode = this.baseReportForm.get('DetectionTypeCode')?.value;

          if (elem.DetectionCode === detectionTypeCode) {
            return true;
          }
          return false;
        })
        this.baseReportForm.get(key)?.setValue(detectionTypeChoiceObj[0].ID);
      }
    });

    this.spinnerService.show();
    this.reportService.addReport(this.baseReportForm.value)
      .subscribe(
        (res) => {
          if (res.Success) {
            let reportNumber = res.Result.ReportNumber;
            let reportID = res.Result.ReportId;

            this.spinnerService.hide();

            this.router.navigate(['/confirmation'], {
              queryParams: {
                reportNumber,
                reportID
              }
            });
          } else {
            this.statusMessage = 'Error occurred while saving new FOD report';
            this.spinnerService.hide();
          }
        },
        (err) => {
          console.log('Add Report ERROR', err);
          this.statusMessage = 'Error occurred while saving new FOD report';
          this.spinnerService.hide();
        }
      )

  }
}
