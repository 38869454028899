import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { PasswordRuleType } from "../../app/model/shared";
import { FAALocalStorageService } from "../../app/services/localStorage.service";
import { SharedService } from "../../app/services/shared.service";
import { AuthorizeService } from "../authorize.service";
import { SpinnerService } from "../../app/services/spinner.service";

@Component({
  selector: 'login-dialog',
  templateUrl: 'login-dialog.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginDialog {
  title = 'Login';
  loginForm: UntypedFormGroup;
  message: string = '';
  hasForgotPassword = false;
  hasPassCode = false;
  alertType = 'success';
  type = 'LOGIN';
  user: any = {};
  roles: any = {};
  hasLoadedRoles = false;
  role = 'PUBLIC';
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<LoginDialog>, public authorizeService: AuthorizeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public http: HttpClient, private sharedService: SharedService,
    private localStorageService: FAALocalStorageService, private spinnerService: SpinnerService,
    private router: Router) { }


  async ngOnInit() {
    this.role = 'PUBLIC';
    this.loadRoles();
    this.initForm();

  }
  initForm() {

    this.type = this.data.type;
    this.user = this.data.user;

    this.message = '';
    if (this.type == 'LOGIN') {
      this.loginForm = this.fb.group({
        email: ['', Validators.required],
        userName: '',
        password: ['', (!this.hasForgotPassword ? Validators.required : !Validators.required)],
        newPassword: ['', this.hasPassCode ? [Validators.required] : !Validators.required],
        passcode: ['', (this.hasPassCode ? Validators.required : !Validators.required)]
      });
    }
    else if (this.type == 'CHANGE_PASSWORD') {
      this.title = 'Change Password';
      this.loginForm = this.fb.group({
        email: ['', Validators.required],
        userName: '',
        password: ['', Validators.required],
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      });
      this.loginForm.controls.userName.setValue(this.user.userName);
      this.loginForm.controls.email.setValue(this.user.email);
    }

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onLogin() {
    this.message = '';
    this.localStorageService.remove('fod-user');

    this.spinnerService.show();


    this.authorizeService.login(this.loginForm.value)
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;
          this.spinnerService.hide();
          
          this.localStorageService.set('fod-user', _result);
         
          if (_result.success) {
            this.alertType = 'success';
            this.localStorageService.set('fod-user', _result);
            //this.router.navigate(['/'], { queryParams: { refresh: true } });
            this.dialogRef.close('LOGIN_SUCCESS');

            //this.validateRole(true);

          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Authentication unsuccessful<br> ' + _errorInfo;
          }
        },

        error: (err: any) => {
        
          this.spinnerService.hide();
          this.alertType = 'danger';

          this.message = 'Authentication unsuccessful';
        },
        complete: () => {
         
        }
      }
      );

    

  }
  onForgotPasswordToggle() {
    this.title = 'Forgot Password';
    this.hasForgotPassword = !this.hasForgotPassword;

    this.hasPassCode = (this.hasForgotPassword ? false : this.hasPassCode)
    this.initForm();
  }
  onHasPassCode() {
    this.title = 'Forgot Password';
    this.hasPassCode = true;
    this.initForm();
  }
  onBackToLogin() {
    this.title = 'Login';
    this.hasForgotPassword = false;
    this.hasPassCode = false;
    this.initForm();

  }

  onSendPassCode() {
    this.hasPassCode = false;
    this.message = '';

    this.spinnerService.show();
    this.authorizeService.sendPasscode(this.loginForm.value)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.alertType = 'success';
            this.message = 'We have emailed passcode, please enter it with New password.';
            setTimeout(() => {
              this.hasPassCode = true;
              //this.dialogRef.close();
            }, 500);

          }
          else {
            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Unable to send passcode, Please try it again!<br> ' + _errorInfo;
          }
        },

        error: (err: any) => {
        
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          console.log(_err);
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Unable to send passcode, Please try it again!<br> ' + _errorInfo;

        },
        complete: () => { }
      }
      );

  
  }

  onForgotPassword() {

    this.message = '';
    let _user: any = {};
    this.spinnerService.show();
    this.authorizeService.forgotPassword(this.loginForm.value)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.alertType = 'success';
            this.message = 'Password changed successfully, Please login with new password.';
            setTimeout(() => {
              this.dialogRef.close('LOGOUT');
            }, 2000);

          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Unable to change password, Please try it again!<br> ' + _errorInfo;
          }

        },

        error: (err: any) => {
         
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          console.log(_err);
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Unable to change password, Please try it again!<br> ' + _errorInfo;

        },
        complete: () => { }
      }
      );


  }

  onChangePassword() {

    this.message = '';
    let _user: any = {};
    this.spinnerService.show();
    this.authorizeService.changePassword(this.loginForm.value)
      .subscribe({
        next: (result) => {
          this.spinnerService.hide();
          let _result: any = {};
          _result = result;

          if (_result.success) {
            this.alertType = 'success';
            this.message = 'Password changed successfully, Please login with new password.';
            setTimeout(() => {
              this.dialogRef.close('LOGOUT');
            }, 2000);

          }
          else {

            this.alertType = 'danger';
            let _errorInfo = _result && _result.errormessage ? _result.errormessage : '';
            this.message = 'Unable to change password, Please try it again!<br> ' + _errorInfo;
          }

        },

        error: (err: any) => {
          this.spinnerService.hide();
          let _err: any = {};
          _err = err;
          this.alertType = 'danger';
          console.log(_err);
          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          this.message = 'Unable to change password, Please try it again!<br> ' + _errorInfo;

        },
        complete: () => { }
      }
      );

  }

  setCurrentRole(selected: string) {

    if (this.roles && Object.keys(this.roles).length > 0) {
      let roleDefault = this.roles.find(c => c.name === selected);

      if (roleDefault && this.loginForm.get('role')?.value && this.loginForm.get('role')?.value !== null)
        this.loginForm.get('role').setValue(roleDefault.name);
    }
  }
  loadRoles() {
    this.hasLoadedRoles = false;
    this.roles = {};
    //this.spinnerService.show();
    this.authorizeService.getRoles()
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;

          this.hasLoadedRoles = true;
          if (_result.success) {

            this.roles = result.result;
            this.setCurrentRole(this.role);
          }

        },

        error: (err: any) => {
          this.hasLoadedRoles = true;
          let _err: any = {};

          _err = err;
          this.alertType = 'danger';

          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          // this.message = 'Error while loading roles<br> ' + _errorInfo;
          //this.spinnerService.hide();
        },
        complete: () => { }
      }
      );

  }
  validPasswordRule(ruleType, val) {
    switch (ruleType) {
      case 'NUMBER': return this.sharedService.validatePasswordRule(PasswordRuleType.Number, val);
      case 'UPPER': return this.sharedService.validatePasswordRule(PasswordRuleType.UpperCase, val);
      case 'LOWER': return this.sharedService.validatePasswordRule(PasswordRuleType.LowerCase, val);
      case 'SPECIAL': return this.sharedService.validatePasswordRule(PasswordRuleType.SpecialCharacter, val);
      case 'MIN_LENGTH': return val.length >= 8;
    }
  }

  hasValidPasswordAndForm() {
    let val = this.loginForm.get('newPassword').value;
    let valid = (this.sharedService.validatePasswordRule(PasswordRuleType.Number, val) &&
      this.sharedService.validatePasswordRule(PasswordRuleType.UpperCase, val) &&
      this.sharedService.validatePasswordRule(PasswordRuleType.LowerCase, val) &&
      this.sharedService.validatePasswordRule(PasswordRuleType.SpecialCharacter, val)
    );
    if (this.loginForm.invalid)
      return false;
    else
      return valid;

  }


}
export function phoneValidator(c: AbstractControl) {
  const validCharacters = /^[()-\s\d]+$/;
  if (c.value.length <= 7)
    return { validPhone: true };
  if (c.value && (!validCharacters.test(c.value))) {

    return { validPhone: true };
  }

  return null;
}

export function emailValidator(control: AbstractControl) {

  if (control.value && (!control.value.includes('@') || !control.value.includes('.'))) {
    return { validEmail: true };
  }
  return null;
}

