import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../services/spinner.service';
@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrl: './print.component.css'
})
export class PrintComponent implements OnInit {
  title: string = '';
  reportNumber: string = '';

  reportMode: string = 'printreport';

  foundFODReport: any = null;

  statusMessage: string = '';

  constructor(private route: ActivatedRoute, public reportService: ReportService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reportNumber = this.route.snapshot.queryParams['reportNumber'];

    this.spinnerService.show();

    let resultFODReport: any = null;

    this.reportService.getFODReportByReportNumber(this.reportNumber)
      .subscribe(
        (res) => {
          if (res.Success) {
            resultFODReport = res.Result;
            /*
            this.foundFODReport = res.Result;

            if (this.foundFODReport['CollectionTypeID'] === 1) {
              this.title = 'View Report: Bulk Collection';
            } else {
              this.title = 'View Report: Single / Multiple Items';
            }

            this.spinnerService.hide();
            */
          } else {
            this.spinnerService.hide();
            this.statusMessage = 'FOD Report with Confirmation Number ' + this.reportNumber + ' could not be found.';
          }
        },
        (err) => {
          console.log('ERR in print component', JSON.stringify(err));
          this.spinnerService.hide();
          this.statusMessage = `An error occured when attempting to retrieve FOD Report: ${err}`;

        },
        () => {
          this.foundFODReport = resultFODReport;

          if (this.foundFODReport['CollectionTypeID'] === 1) {
            this.title = 'View Report: Bulk Collection';
          } else {
            this.title = 'View Report: Single / Multiple Items';
          }

          this.spinnerService.hide();
        }
      )

  }

  handleBaseReportUpdate(obj: any) {
    this.foundFODReport = obj;
    this.title = `View Report: ${this.foundFODReport['CollectionDesc']}`;
  }

}
