import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthorizeService } from '../authorize.service';
import { UserDialog } from '../../app/admin/user/user-dialog.component';
import { LoginDialog } from './login-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FAALocalStorageService } from '../../app/services/localStorage.service';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit, OnDestroy {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public password: Observable<string>;
  public role = 'PUBLIC';
  navigationSubscription;
  result: any;
  type = 'LOGIN';
  user: any = {};

  constructor(private authorizeService: AuthorizeService, public dialog: MatDialog, private localStorageService: FAALocalStorageService, public router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser();
    this.role = this.authorizeService.getUserRole();
    this.user = this.authorizeService.getUserData();

  }
  ngOnDestroy() {
  }

  openLoginDialog(type): void {
    this.type = type;
    const dialogRef = this.dialog.open(LoginDialog, {
      width: '400px',
      data: { user: this.user, isAuthenticated: false, type: this.type }

    });

    dialogRef.afterClosed().subscribe(result => {

      this.result = result;
      console.log('login menu dialog closed --' + this.result);

      if (this.result == 'LOGIN_SUCCESS') {
        console.log(this.result);
        // this.reloadCurrentRoute();
        // this.router.navigate(["/"], { skipLocationChange: true });
        window.location.reload();
      }
      else if (this.result == 'LOGOUT') {
        this.onlogout();
        this.ngOnInit();
        //this.router.navigate(['/']);
      }
    });
  }

  onUserDialog(type): void {
    this.type = type;
    const dialogRef = this.dialog.open(UserDialog, {
      width: '400px',
      data: { type: this.type, user: this.user }

    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    console.log('reloadCurrentRoute');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onlogout() {
    this.localStorageService.remove('fod-user');
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    //alert('lpgin menu - onlogout');
    window.location.reload();
    this.router.navigate(['/']);
  }

}
