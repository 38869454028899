import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-mat-side-toggle',
  template: `
        <mat-slide-toggle [ngModel]="checked" ngDefaultControl (ngModelChange)="onChange($event)"  [disabled]="disabled" color="primary"></mat-slide-toggle>
 
    `,
  styleUrls: ['./mat-side-toggle.component.css']
})
export class MatSideToggleComponent implements ICellRendererAngularComp {
  params: any;
  checked: boolean = false;
  disabled: boolean = false;
  agInit(params: any): void {
    
    this.params = params;
  
    this.checked = params.value;// this.params.value == '1' || this.params.value == 1 || this.params.value == true || this.params.value == "On" || this.params.value == "TRUE" ? true : false;
   
    if (params.userName && params.userName !== '' && params.data.createdby)
      this.disabled = params.userName !== params.data.createdby;

  }

  // demonstrates how you can do "inline" editing of a cell
  onChange(checked: boolean) {
    this.checked = checked;
   
   this.params.node.setDataValue(this.params.column.colId, this.checked ? 1 : 0);
   
    if (this.params.eGridCell) {
      this.params.eGridCell.focus();
      this.params.clicked(this.params);
    }
   
  }

  refresh(params: any): boolean {
    return false;
  }
}
