<!-- <div class="content">
  <iframe [src]="src | safe" width="100%" height="500px"></iframe>
</div> -->

<div class="content">
  <mat-card class="wildlife-card">
    <mat-card-header>
      <mat-card-title> Edit a FOD Report </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <hr />
      <div class="card">
        <div class="card-header primary-header">
          Enter Report Confirmation Number
        </div>
        <div class="card-body">
          <div class="row g-3 align-items-center">
            <div class="col-auto">
              <label for="confirmationnumber" class="col-form-label">
                <span class="text-danger">*</span>Report Confirmation
                Number:
              </label>
            </div>
            <div class="col-5">
              <input type="text"
                     required
                     class="form-control"
                     id="confirmationnumber"
                     name="confirmationnumber"
                     [(ngModel)]="confirmationNumber"
                     #confirmation="ngModel" />
            </div>
            <div class="col-auto text-start">
              <button type="submit"
                      class="btn btn-primary"
                      (click)="searchFODReport()"
                      [disabled]="confirmation?.errors?.required">
                Submit
              </button>
            </div>
          </div>
       
        </div>
      </div>
      <ngb-alert [dismissible]="false" [type]="alertType" *ngIf="message">
        {{ message }}
      </ngb-alert>
    </mat-card-content>
  </mat-card>
</div>
