import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationStart } from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthorizeService } from '../../../api-authorization/authorize.service';
import { LoginDialog } from '../../../api-authorization/login-menu/login-dialog.component';
import { UserDialog } from '../../admin/user/user-dialog.component';
import { FAALocalStorageService } from '../../services/localStorage.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  navbarOpen = false;

  role: string = 'PUBLIC';
  public userName: Observable<string>;
  public isAuthenticated: Observable<boolean>;
  version = '2.0.0 Date: 10/12/2024';
  message: string = '';
  currentTime = new Date().getTime();

  public password: Observable<string>;

  navigationSubscription;
  result: any;
  type = 'LOGIN';
  isDevelopmentEnv = false;
  user: any = {};
  roles: any = {};
  isUserLoggedIn: boolean;

  private saveRouterStrategyReuseLogic: any;
  constructor(public authorizeService: AuthorizeService, public dialog: MatDialog, private localStorageService: FAALocalStorageService, public router: Router, private http: HttpClient) {
   
  }
  ngOnInit() {
    this.saveRouterStrategyReuseLogic = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = (future, curr) => { return false; };
   
    this.setAppDataValues();

  }
  setAppDataValues(){
    let currentVer = this.localStorageService.get('fod-version') || '';

    this.http.get('../../assets/appData.json?v=' + this.currentTime)
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;
          this.version = _result.version;
          this.isDevelopmentEnv = _result.isDevelopmentEnv;

          if (currentVer !== this.version) {

            // if newever version, will automtically logout the user
            this.localStorageService.clear();
            this.localStorageService.set('fod-version', this.version);
            this.isAuthenticated = of(false);
            this.router.navigate(['/home']);

          }
          else {
            this.getUserData();

          }
        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          this.version = '2.0.0 Date: 10/12/2024';
        },
        complete: () => {
          //complete
        }
      }
      );

  }
  
  getUserData() {

    this.isAuthenticated = this.authorizeService.isAuthenticated();

    this.userName = this.authorizeService.getUser();
    this.role = this.authorizeService.getUserRole();
    this.user = this.authorizeService.getUserData();

    this.checkUserLogin();
  }
  checkUserLogin() {
    let _user = this.authorizeService.getUserData();
    let hasAuthenticated = this.authorizeService.isAuthenticated();

    if (Object.keys(_user).length > 0) {

      this.role = this.authorizeService.getUserRole();

      this.userName = this.authorizeService.getUser();
      this.isAuthenticated = of(true);
      this.loadRoles();
    }
    else {
      this.isAuthenticated = of(false);
      this.onLogout();

    }
  }

  openLoginDialog(type): void {
    this.type = type;

    const dialogRef = this.dialog.open(LoginDialog, {
      width: '400px',
      data: { user: this.user, isAuthenticated: false, type: this.type }

    });

    dialogRef.afterClosed().subscribe(result => {

      this.result = result;
      //console.log('login menu dialog closed --' + this.result);
   
      if (this.result == 'LOGIN_SUCCESS') {
        console.log(this.result);

        // this.reloadCurrentRoute();
        // this.router.navigate(["/"], { skipLocationChange: true });
        window.location.reload();
      }
      else if (this.result == 'LOGOUT') {
        this.localStorageService.remove('fod-user');
        this.onLogout();
        this.ngOnInit();
        this.router.navigate(['/']);
      }
    });
  }

  onUserDialog(type): void {
    this.type = type;
    const dialogRef = this.dialog.open(UserDialog, {
      width: '400px',
      data: { type: this.type, user: this.user }

    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserData();
    });
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onLogout() {
    this.role = 'PUBLIC';
    this.localStorageService.remove('fod-user');
    this.userName = this.authorizeService.getUser();
    this.isAuthenticated = of(false);
    this.authorizeService.logout();

  }
  loadRoles() {

    this.roles = {};
    //this.ngxService.show();
    this.authorizeService.getRoles()
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;

          if (_result.success) {

            this.roles = result.result;
            this.validateRole();

          }

        },

        error: (err: any) => {
          let _err: any = {};

          _err = err;

          let _errorInfo = '';//err.error && _err.error.ModelState[''] && _err.error.ModelState[''][0] ? '<br>' + _err.error.ModelState[''][0] : '';
          // this.message = 'Error while loading roles<br> ' + _errorInfo;
          //this.ngxService.hide();
        },
        complete: () => { }
      }
      );

      

  }
  validateRole(hasLoginEvent: boolean = false) {
    let _user = this.authorizeService.getUser();
    let hasAuthenticated = this.authorizeService.isAuthenticated();

    if (Object.keys(_user).length > 0) {

      this.role = this.authorizeService.getUserRole();

      this.userName = this.authorizeService.getUser();
      this.isAuthenticated = of(true);

      //if (hasLoginEvent) {
      //  // any redirect after login
      //}
    }
    else {

      this.localStorageService.remove('fod-user');
      this.isAuthenticated = of(false);

      this.router.navigate(['/']);

      // console.log('unauthenticated');
    }
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }



}
