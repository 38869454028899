import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FAALocalStorageService } from '../../services/localStorage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  projectManager = 'Wesley Major';
  projectManagerEmail = 'wesley.major@faa.gov';
  version = '2.0.0 Date: 10/12/2024';
  isDevelopmentEnv = false;
  currentTime = new Date().getTime();

  constructor(public localStorageService: FAALocalStorageService, private http: HttpClient) { }

  ngOnInit() {

    this.setAppDataValues();
  }
  setAppDataValues() {
    let currentVer = this.localStorageService.get('fod-version') || '';

    this.http.get('../../assets/appData.json?v=' + this.currentTime)
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;

          this.version = _result.version;
          // currentVer = _result.version;
          this.projectManager = _result.projectManager;
          this.projectManagerEmail = _result.projectManagerEmail;
          this.isDevelopmentEnv = _result.isDevelopmentEnv;
          if (currentVer !== this.version) {
            this.localStorageService.clear(); // remove all
            //this.localStorageService.remove('wildlife-user');
            this.localStorageService.set('fod-version', this.version);

            ////not always required

            // this.localStorageService.remove('wildlife-roles');
            //this.localStorageService.remove('wildlife-damageLevels');
            // this.localStorageService.remove('wildlife-personReportings');
            //this.localStorageService.remove('wildlife-sourceofReports');

            // this.localStorageService.remove('wildlife-states');


          }
        },

        error: (err: any) => {
          this.projectManager = 'Wesley Major';
          this.projectManagerEmail = 'wesley.major@faa.gov';
          this.version = '2.0.0 Date: 10/12/2024';
        },
        complete: () => {
          //complete
        }
      }
      );
  }

}
