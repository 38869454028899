<ul class="navbar-nav" *ngIf="isAuthenticated | async">
  <li class="nav-item">
    <a class="nav-link text-dark" [routerLink]='["/authentication/profile"]' title="Manage">Hello {{ userName | async }}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-dark" (click)="onlogout()" [state]='{ local: true }' title="Logout">Logout</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-dark" (click)="onUserDialog('PROFILE')" title="Update Profile">Update Profile</a>
  </li>
  <li class="nav-item">
    <a class="nav-link text-dark" (click)="openLoginDialog('CHANGE_PASSWORD')" title="Change Password">Change Password</a>
  </li>
  <!--<li class="nav-item" *ngIf="role == 'ADMIN' ">
    <a class="nav-link text-dark" (click)="openLoginDialog('NEW_USER')" title="NEW_USER">Create New User</a>
  </li>-->

  <li class="nav-item"  *ngIf="role == 'ADMIN' ">
    <a class="nav-link text-dark" [routerLink]='["/manageUser"]' title="Manage">Manage Users</a>
  </li>

</ul>

<ul class="navbar-nav" *ngIf="!(isAuthenticated | async)">
  <!--<li class="nav-item">
        <a class="nav-link text-dark" [routerLink]='["/authentication/register"]'>Register</a>
    </li>-->
  <li class="nav-item">
    <!--<a class="nav-link text-dark" [routerLink]='["/authentication/login"]'>Login</a>-->
    <a class="nav-link text-dark" (click)="openLoginDialog('LOGIN')"> Login</a>
  </li>
</ul>
