import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '../services/report.service';
import { SpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrl: './update.component.css'
})
export class UpdateComponent implements OnInit {

  title: string = '';
  reportMode: string = 'updatereport';
  reportNumber: string = '';
  statusMessage: string = '';
  foundFODReport: any = '';

  constructor(private route: ActivatedRoute, public reportService: ReportService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.reportNumber = this.route.snapshot.queryParams['reportNumber'];

    this.spinnerService.show();

    let resultFODReport: any = null;

    this.reportService.getFODReportByReportNumber(this.reportNumber)
      .subscribe(
        (res) => {
          if (res.Success) {
            resultFODReport = res.Result;
          } else {
            this.spinnerService.hide();
            this.statusMessage = 'FOD Report with Confirmation Number ' + this.reportNumber + ' could not be found.';
          }
        },
        (err) => {
          console.log('ERR in Update component', JSON.stringify(err));
          this.spinnerService.hide();
          this.statusMessage = `An error occured when attempting to retrieve FOD Report: ${err}`;

        },
        () => {
          this.foundFODReport = resultFODReport;

          if (this.foundFODReport['CollectionTypeID'] === 1) {
            this.title = 'Edit Report: Bulk Collection';
          } else {
            this.title = 'Edit Report: Single / Multiple Items';
          }

          this.spinnerService.hide();
        }
      )
  }

}

