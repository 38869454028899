
import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { AuthorizeService } from "../../api-authorization/authorize.service";

@Component({
  selector: 'authorizeAccess-dialog',
  templateUrl: 'authorizeAccess-dialog.html'
})
export class AuthorizedAccessDialog {
  title = 'Unauthorized Access';
  projectManager = 'Wesley Major';
  projectManagerEmail = 'wesley.major@faa.gov';

  currentTime = new Date().getTime();
  constructor(private http: HttpClient, public dialogRef: MatDialogRef<AuthorizedAccessDialog>, public authorizeService: AuthorizeService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() {
    this.setAppDataValues();
  }
  setAppDataValues() {
    this.http.get('../../assets/appData.json?v=' + this.currentTime)
      .subscribe({
        next: (result) => {
          let _result: any = {};
          _result = result;

          this.projectManager = _result.projectManager;
          this.projectManagerEmail = _result.projectManagerEmail;

        },

        error: (err: any) => {
          let _err: any = {};
          _err = err;
          this.projectManager = 'Wesley Major';
          this.projectManagerEmail = 'wesley.major@faa.gov';

        },
        complete: () => {
          //complete
        }
      }
      );

  }

}
