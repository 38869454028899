import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from '../services/report.service';
import { SpinnerService } from '../services/spinner.service';
@Component({
  selector: 'app-editreport',
  templateUrl: './editreport.component.html',
  styleUrl: './editreport.component.css'
})
export class EditreportComponent implements OnInit {

  //src: any = "https://fod.faa.gov/EditReport.aspx";

  confirmationNumber: string = '';
  alertType: string = 'danger';
  message: string = '';

  constructor(private router: Router, public reportService: ReportService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
  }

  searchFODReport() {
    console.log('confirmation number', this.confirmationNumber);

    if (this.confirmationNumber.length <= 0) {
      this.message = 'Report Confirmation Number cannot be blank.';
      return;
    } else {

      this.spinnerService.show();

      this.reportService.getFODReportByReportNumber(this.confirmationNumber)
        .subscribe(
          (res) => {
            if (res.Success) {
              this.spinnerService.hide();
              // Navigate to Update component
              this.router.navigate(['/update'], { queryParams: { reportNumber: this.confirmationNumber } });
            } else {
              this.message = 'Invalid Report Number';
              this.spinnerService.hide();
            }
          },
          (err) => {
            console.log(err);
            this.message = `An error occured when attempting to retrieve FOD Report: ${err}`;
          })
      // Navigate to Update component
      //      this.router.navigate(['/update'], { queryParams: { reportNumber: this.confirmationNumber } });
    }

  }
}



