<!--
'DetectionDate', 'ReportNumber', 'AIRPORT', 'FODItemsCount'

-->
<!--
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <ng-container matColumnDef="DetectionDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element">{{ element.DetectionDate }}</td>
  </ng-container>

  <ng-container matColumnDef="ReportNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Report No.</th>
    <td mat-cell *matCellDef="let element">
      <a
        target="_blank"
        [routerLink]="['/printreport']"
        [queryParams]="{ reportNumber: element.ReportNumber }"
        >{{ element.ReportNumber }}</a
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="AIRPORT">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Airport</th>
    <td mat-cell *matCellDef="let element">{{ element.AIRPORT }}</td>
  </ng-container>

  <ng-container matColumnDef="FODItemsCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>FOD Items</th>
    <td mat-cell *matCellDef="let element">{{ element.FODItemsCount }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator [length]="dataSource.length" [pageSize]="10"></mat-paginator> -->
<ng-container>
  <!-- Filter -->
  <ng-container *ngIf="isFilterable" [matColumnDef]="this.rowActionIcon">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="filter" />
    </mat-form-field>
  </ng-container>

  <!-- Table -->
  <table mat-table
         [dataSource]="tableDataSource"
         matSort
         (matSortChange)="sortTable($event)">
    <!-- action column -->
    <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="rowActionIcon">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell
          *matCellDef="let element"
          [id]="rowActionIcon"
          (click)="emitRowAction(element)">
         <button mat-button  [title]="rowActionTitle">
           <mat-icon>visibility</mat-icon>
        </button> 
        <!--<button class="btn btn-sm btn-outline-dark"
                [title]="rowActionTitle"
                placement="bottom">
          <i [ngClass]="rowActionIcon" aria-hidden="true"></i>
        </button>-->
      </td>
    </ng-container>
    <ng-container *ngFor="let tableColumn of tableColumns"
                  [matColumnDef]="tableColumn.name">
      <!-- if sortable column header -->
      <ng-container *ngIf="tableColumn.isSortable; else notSortable">
        <th mat-header-cell
            *matHeaderCellDef
            [mat-sort-header]="tableColumn.name"
            [arrowPosition]="
            tableColumn.position === 'right' ? 'before' : 'after'
          ">
          {{ tableColumn.name }}
        </th>
      </ng-container>
      <!-- else not sortable -->
      <ng-template #notSortable>
        <th mat-header-cell
            *matHeaderCellDef
            [class.text-right]="tableColumn.position == 'right'">
          {{ tableColumn.name }}
        </th>
      </ng-template>

      <!-- column data -->
      <td mat-cell
          *matCellDef="let element"
          [class.text-right]="tableColumn.position == 'right'">
        {{ element | dataPropertyGetter: tableColumn.dataKey }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- Pagination -->
  <mat-paginator *ngIf="isPageable"
                 [pageSizeOptions]="paginationSizes"
                 [pageSize]="defaultPageSize"
                 showFirstLastButtons>
  </mat-paginator>
</ng-container>
