<app-modal #modal [modalConfig]="modalConfig">
  <div class="text-center" *ngIf="modalType === 'view'">
    <img id="image" [src]="imageSrc" class="img-fluid" />
  </div>
  <div class="card-body" *ngIf="modalType === 'delete'">
    <strong>Are you sure you want to DELETE the selected image?</strong>
  </div>
</app-modal>

<form [formGroup]="fodItemReportForm">
  <div class="card">
    <div class="card-header bg-transparent border-bottom-0">
      <div class="col-12 text-end">
        <button *ngIf="FODItemReportMode !== 'printreport'"
                type="button"
                class="btn btn-outline-secondary btn-sm text-end"
                (click)="onDeleteFodItem()"
                aria-label="Remove FOD Item" matTooltip="Remove FOD Item"
                data-testid="delete-foditem-button">
          <span aria-hidden="true">x</span>
        </button>
      </div>
    
    </div>
    <div class="card-body">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="foditemlocation">Location:</label>
        <div class="col-sm-3">
          <select class="form-control"
                  id="foditemlocation"
                  formControlName="LocationID"
                  (change)="onSelectHandler($event, 'LocationID')"
                  data-testid="location-foditem-select">
            <option *ngFor="let choice of fodItemLocationArr; let i = index"
                    [ngValue]="fodItemLocationArr[i].ID">
              {{ fodItemLocationArr[i].LocationDesc }}
            </option>
          </select>
        </div>
        <label class="col-sm-3 col-form-label" for="foditemsize">Size:</label>
        <div class="col-sm-3">
          <select class="form-control"
                  id="foditemsize"
                  formControlName="SizeID"
                  (change)="onSelectHandler($event, 'SizeID')"
                  data-testid="size-foditem-select">
            <option *ngFor="let choice of fodItemObjectSizesArr; let i = index"
                    [ngValue]="fodItemObjectSizesArr[i].ID">
              {{ fodItemObjectSizesArr[i].SizeDesc }}
            </option>
          </select>
        </div>
        <label class="col-sm-3 col-form-label" for="foditemdescription">Description:</label>
        <div class="col-sm-3">
          <select class="form-control"
                  id="foditemdescription"
                  formControlName="ObjectTypeID"
                  (change)="onSelectHandler($event, 'ObjectTypeID')"
                  data-testid="description-foditem-select">
            <option *ngFor="let choice of fodItemDescriptionArr; let i = index"
                    [ngValue]="fodItemDescriptionArr[i].ID">
              {{ fodItemDescriptionArr[i].ObjectDesc }}
            </option>
          </select>
        </div>
      
        <label #labelspecifyother
               [className]="hideSpecifyOther ? 'hide' : 'col-sm-3 col-form-label'"
               for="foditemspecify">
          Specify Other:
        </label>
        <div #divspecifyother
             [className]="hideSpecifyOther ? 'hide' : 'col-sm-3'">
          <input type="text"
                 class="form-control"
                 id="foditemspecify"
                 formControlName="ObjectTypeOtherText"
                 (change)="onChangeTextHandler($event, 'ObjectTypeOtherText')"
                 data-testid="specifyother-foditem-input" />
        </div>

        <label class="col-sm-3 col-form-label" for="foditemsizedesignator">Designator:</label>
        <div class="col-sm-3">
          <input type="text"
                 class="form-control"
                 id="foditemsizedesignator"
                 formControlName="DesignatorDescription"
                 (change)="onChangeTextHandler($event, 'DesignatorDescription')"
                 data-testid="designator-foditem-input" />
        </div>
        <label class="col-sm-12 col-form-label" for="foditemlocation">Remarks:</label>
        <div class="col-sm-12">
          <textarea class="form-control"
                    maxlength="4000"
                    formControlName="Remark"
                    rows="4"
                    (change)="onChangeTextHandler($event, 'Remark')"
                    data-testid="remarks-foditem-textarea">
          </textarea>
        </div>
      </div>

      <!--Original Image Upload Section-->
      <div *ngIf="FODItemReportMode !== 'printreport'" class="form-group">
        <label class="col-sm-12 col-form-label">Upload an Image File for a Current FOD Item</label>
        <div class="row">
          <div class="col-md-6">
            <input type="file"
                   id="photo"
                   #photo
                   class="col-md-9"
                   accept="image/png, image/jpeg, image/jpg"
                   (change)="getUploadFileCount($event)" />

            <span class="col-md-2">
              <button type="button"
                      class="btn btn-primary btn-sm"
                      [disabled]="!(fileUploadCount > 0)"
                      (click)="uploadImage()"
                      data-testid="upload-foditem-button">
                Upload
              </button>
            </span>
          </div>
          <div *ngIf="Images.length > 0" class="col-md-6">
            <table class="table table-sm table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th width="30%">Name</th>
                  <th width="10%">Status</th>
                  <th width="30%">Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-item [ngForOf]="Images">
                  <tr *ngIf="
                      (item.Action !== 'DELETE' && item.Action === 'ADD') ||
                      item.Action === '' ||
                      item.Action === 'LOADED_FROM_SERVER'
                    "
                      [id]="item.ImageBodyId">
                    <td>
                      <strong>{{ item.FileName }} </strong>
                    </td>

                    <td class="text-center">
                      <span *ngIf="
                          item.Action == null ||
                          item.Action === 'ADD' ||
                          item.Action === '' ||
                          item.Action === 'LOADED_FROM_SERVER'
                        ">
                        <i class="fa fa-check-circle text-success"
                           placement="bottom"
                           ngbTooltip="Uploaded"><mat-icon>check_circle</mat-icon></i>
                      </span>
                    </td>
                    <td>
                      {{ item.Description }}
                    </td>

                    <td>
                      <button type="button"
                              class="btn btn-outline-dark btn-s btn-sm"
                              style="margin: 0px !important"
                              placement="bottom"
                              ngbTooltip="Preview"
                              (click)="openModal(item, 'view')"
                              data-testid="viewimage-foditem-button">
                        <mat-icon class="btn-sm">visibility</mat-icon>
                      </button>&nbsp;&nbsp;
                      <button type="button"
                              *ngIf="
                          item.Action === 'ADD' ||
                          FODItemReportMode !== 'printreport'
                        "
                              class="btn btn-danger btn-s btn-sm"
                              placement="bottom"
                              style="margin: 0px !important"
                              ngbTooltip="Delete"
                              (click)="openModal(item, 'delete')"
                              data-testid="deleteimage-foditem-button">
                        <mat-icon>cancel</mat-icon>

                      </button>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-12 col-form-label" for="foditemimagedesc">Image Description:</label>
          <div class="col-sm-12">
            <textarea class="form-control"
                      maxlength="4000"
                      formControlName="ImageDescription">
            </textarea>
          </div>
        </div>
      </div>
      <!--Original Image Section-->
      <!-- Print Mode Image Section-->
      <div *ngIf="FODItemReportMode === 'printreport'" class="form-group">
        <div class="row">
          <ng-template #noImages>
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <strong><h4>No Image Currently Uploaded</h4></strong>
            </div>
            <div class="col-md-4"></div>
          </ng-template>

          <div *ngIf="Images.length > 0; else noImages" class="col-md-12">
            <table class="table table-sm table-bordered table-hover table-striped">
              <thead class="primary-header">
                <tr>
                  <th width="10%">Loaded On</th>
                  <th width="30%">Name</th>
                  <th width="30%">Description</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-item [ngForOf]="Images">
                  <tr *ngIf="
                      (item.Action !== 'DELETE' && item.Action === 'ADD') ||
                      item.Action === '' ||
                      item.Action === 'LOADED_FROM_SERVER'
                    "
                      [id]="item.ImageBodyId">
                    <td id="loadon">
                      {{ item.LoadDate }}
                    </td>
                    <td id="name">
                      {{ item.FileName }}
                    </td>
                    <td id="imagedesc">
                      {{ item.Description }}
                    </td>
                    <td id="imagepic">
                      <img [id]="'image_' + item.ImageBodyId"
                           [src]="'data:image/jpeg;base64,' + item.Content"
                           class="img-fluid"
                           (click)="openModal(item, 'view')"
                           data-testid="viewimage-foditem-img" />
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Print Mode Image Section-->
    </div>
  </div>
</form>
