import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-mat-checkbox',
 
  template: `
        <mat-checkbox  [ngModel]="checked" ngDefaultControl  (ngModelChange)="onChange($event)" color="primary"></mat-checkbox>
    `,
  styles: [
    `
            ::ng-deep
            .mat-checkbox-layout {
                /* horizontally align the checkbox */
                width: 100%;
                display: inline-block !important;
                text-align: center;
                margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

                /* vertically align the checkbox when not using the ag-material theme - should be the same as the 
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
            }

            ::ng-deep
            .mat-checkbox-layout .mat-ripple-element {
                opacity: 0.2;
            }
        `
  ]
})
export class MatCheckboxComponent implements ICellRendererAngularComp {
   params: any;

   checked: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.checked = this.params.value === "On" || this.params.value === "TRUE" ;
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange(checked: boolean) {
    
    this.checked = checked;
   
    this.params.node.setDataValue(this.params.colDef, this.checked ? "On" : "Off");

    if (this.params.eGridCell) {
      this.params.eGridCell.focus();
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
