import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { tap } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard  {
  constructor(private authorize: AuthorizeService, private router: Router) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
    if (this.authorize.isAuthenticated() &&
      (this.authorize.getUserRole() == 'ADMIN' || this.authorize.getUserRole() == 'REVIEWER' ||
      this.authorize.getUserRole() == 'VIEWER'))
      return true;
    else {
      this.router.navigate(['/home']).then(() => {
        return false;
      });
    }
  }

   handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
   
    if (!isAuthenticated) {
     
      this.router.navigate(['/home']).then(() => {
        setTimeout(() => {
          window.location.reload();}, 0)
       
      });
     
    }
  }
}
