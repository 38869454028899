
<div class="content container">

  <mat-card class="asd-card">

    <div class="alert alert-info" role="alert" *ngIf="homeAnnouncement">
      <i class="fa fa-bullhorn" aria-hidden="true"></i> &nbsp; &nbsp;
      <span [innerHTML]="homeAnnouncement"></span>
    </div>
    <mat-card-header>

      <mat-card-title>
        <h1>{{title}}</h1>
      </mat-card-title>
      <hr>
    </mat-card-header>

    <mat-card-content>

      <div class="container">
     
        <div class="row">
          <div class="d-grid col-4">
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" [routerLink]="'/submitreport'">
              Submit a Report
            </a>
          </div>
          <div class="d-grid col-4">
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" [routerLink]="'/editreport'">
              Edit a Report
            </a>
          </div>
          <div class="d-grid col-4">
            <a role="button" class="btn btn-primary btn-lg btn-block btn-home-link" href="{{fodresourcesUrl}}" target="_blank">
              FOD Resources
            </a>
          </div>
        </div>

        <br />
        <div class="d-none d-sm-block">
          <p>
            FOD is defined in <a href="https://www.faa.gov/airports/resources/advisory_circulars/index.cfm/go/document.current/documentNumber/150_5210-24" target="_blank">
              AC
              150/5210-24, Airport Foreign Object Debris (FOD) Management
            </a>, and is any object, live or not, located in an
            inappropriate location in the airport environment that has the capacity to injure personnel and damage aircraft. FOD
            creates safety hazards and can ultimately impact safe operations. The presence of FOD is a continuing concern at our
            nation's airports. As stated in an <a href="https://www.trb.org/Publications/Blurbs/165911.aspx" target="_blank">Airport Cooperative Research Program Synthesis 26</a>, Foreign Object Debris (FOD)
            exists in many different forms, comes from many different sources, and can be found anywhere on an airport's air
            operations area (AOA). FOD can cause damage to aircraft in the form of torn or punctured tires, punctured airframes,
            nicked turbine or propeller blades, and, in rare instances, even engine failure. FOD can also cause injury to airport
            employees as debris are propelled by jet blast, prop, or rotor wash. FOD directly costs the U.S. aviation industry
            $474 million annually and the global aviation industry $1.26 billion annually. Direct plus indirect costs, such as
            flight delays, cost the U.S. aviation industry $5.2 billion annually and the global aviation industry $13.9 billion
            annually. Airports, airlines, and the general aviation community can assist in documenting the occurrence of FOD by
            submitting reports to the FAA FOD database.
          </p>
        </div>

      </div>
    </mat-card-content>
  
  </mat-card>

</div>
