import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { zip } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';

import { NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ReportService } from '../../services/report.service';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig, Image } from '../../model/common';
import { SpinnerService } from '../../services/spinner.service';
@Component({
  selector: 'app-foditem',
  templateUrl: './foditem.component.html',
  styleUrl: './foditem.component.css'
})
export class FoditemComponent implements OnInit, AfterViewInit {

  fileUploadCount: number = 0;

  FODImageId: number = 0;
  Images: Image[];
  imageName: string = '';
  imageSrc: string = '';
  modalImageRef: NgbModalRef;

  fodItemLocationArr: any[] = [];
  fodItemObjectSizesArr: any[] = [];
  fodItemDescriptionArr: any[] = [];

  fodItemReportForm: FormGroup;

  hideSpecifyOther = true;

  public parentFormGroup: any;

  @Input() parentFODItemsarr: any[] = [];

  @Input() currentFODItemIndex: number = -1;

  @ViewChild('photo') inputPhoto: ElementRef;

  // Modal Component test
  modalConfig: ModalConfig = {
    modalTitle: 'Default Title'
  }

  modalType: string = 'view';

  @ViewChild('modal') private modalComponent: ModalComponent;

  async openModal(item: any, modalType: any) {
    let mapping: {
      [key: string]: any
    }

    // reset Modal config
    this.modalConfig = {
      modalTitle: 'Default Title'
    }

    mapping = {
      'view': () => {
        this.imageName = item.FileName;
        this.imageSrc = 'data:image/jpeg;base64,' + item.Content;
        this.modalConfig.modalTitle = this.imageName;
        this.modalConfig.modalHeaderClass = 'modal-header primary-header-modal';
        this.modalConfig.hideFooter = () => { return true }
        this.modalConfig.modalAction = 'VIEW';
      },
      'delete': () => {
        this.modalConfig.modalTitle = "Delete Image";
        this.modalConfig.modalHeaderClass = 'modal-header bg-danger'
        this.modalConfig.closeButtonLabel = 'No';
        this.modalConfig.dismissButtonLabel = 'Yes';
        this.modalConfig.buttonDismissClass = 'btn btn-danger text-left pull-left';
        this.modalConfig.buttonCloseClass = 'btn btn-outline-dark';
        this.modalConfig.hideFooter = () => { return false }
        this.modalConfig.hideBody = () => { return false }
        this.modalConfig.hideHeaderCloseButton = () => { return true }

        if (this.FODItemReportMode === 'submitareport') {
          this.modalConfig.onDismiss = async () => {
            item.Action = 'DELETE_IN_MEMORY';

            let filteredImages: any[] = this.Images.filter((elem) => {
              if (elem.Action !== 'DELETE_IN_MEMORY') {
                return true;
              }

              return false;
            });

            this.Images = filteredImages;

            // Update Images form control in Base Report Component
            let obj = {
              formControlName: 'Images',
              formControlValue: this.Images
            }

            this.onUpdateFODItem(obj);
          }
        } else {
          // Edit Page mode
          this.modalConfig.onDismiss = async () => {
            item.Action = 'DELETE';
            // Update Images form control in Base Report Component
            let obj = {
              formControlName: 'Images',
              formControlValue: this.Images
            }

            this.onUpdateFODItem(obj);
          }
        }

      }
    }

    this.modalType = modalType;

    let thisFunc = mapping[modalType];
    if (thisFunc) thisFunc();

    return await this.modalComponent.open();
  }

  // submitareport, editareport, viewreport, printreport, updatereport are valid values
  // Base Report component passes this value to FODItem Component
  @Input() FODItemReportMode: any = null;

  constructor(public reportService: ReportService, private spinnerService: SpinnerService, private fb: FormBuilder, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngAfterViewInit(): void {
  }

  @Output() public onDelete: EventEmitter<any> = new EventEmitter();
  @Output() public onUpdate: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    //this.populateFodItemLocationArray();
    //this.parentFormGroup = this.controlContainer.control;

    this.initFODItemByReportMode(this.FODItemReportMode);
  }

  initFODItemByReportMode(reportMode: string) {
    switch (reportMode) {
      case 'submitareport': {
        this.initFODItemForm();
        this.populateFodItemInputs(true);
        this.Images = [];
        break;
      }
      case 'printreport': {
        this.initFODItemForm();
        this.populateFodItemInputs(false);
        this.setFODItemInputValues(true)
        break;
      }
      case 'updatereport': {
        this.initFODItemForm();
        this.populateFodItemInputs(false);
        this.setFODItemInputValues(false)
        break;
      }
      default: {
        console.log('Unknown report mode');
        break;
      }
    }
  }

  setFODItemInputValues(readOnly: boolean) {
    let currFODItem = this.parentFODItemsarr[this.currentFODItemIndex];
    Object.keys(this.fodItemReportForm.controls).forEach((key) => {
      if (key === 'ObjectTypeOtherText') {
        if (currFODItem[key]) {
          this.hideSpecifyOther = false;
        }
      }
      this.fodItemReportForm.get(key)?.setValue(currFODItem[key]);
      if (readOnly)
        this.fodItemReportForm.get(key)?.disable();
    });

    //let currFODItemImages = currFODItem['Images'];
    //this.Images.push(...currFODItemImages);

    // 10-19-21
    //this.Images = currFODItem['Images'] ? currFODItem['Images'] : [];
    if (currFODItem['Images'])
      this.Images = currFODItem['Images'];
    else
      this.Images = [];

    if (this.FODItemReportMode === 'updatereport' && this.Images.length > 0) {
      for (let i = 0; i < this.Images.length; i++) {
        let current = this.Images[i];
        current.Action = 'LOADED_FROM_SERVER';
        console.log('current', current)
      }
    }
    //this.Images = currFODItem['Images'];
  }

  getUploadFileCount(fileInput: Event) {
    let file = (fileInput.target as HTMLInputElement);
    const numebrOfFiles = file?.files?.length;

    if (numebrOfFiles) {
      this.fileUploadCount = numebrOfFiles;
    }
  }

  uploadImage() {
    if (this.fileUploadCount > 0) {
      let allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
      let type = this.inputPhoto.nativeElement.files[0].type;

      if (!type || type === '' || allowedTypes.indexOf(type) === -1) {
        alert('Unsupported file type selected.');
        return;
      } else {
        let image = this.inputPhoto.nativeElement.files.item(0);
        const formData = new FormData();
        formData.append("photo", image);

        this.reportService.getImageAsBytes(formData)
          .subscribe(
            (res) => {
              let convertedImage = {} as Image;

              if (this.FODItemReportMode === 'submitareport' || this.FODItemReportMode === 'updatereport') {
                convertedImage.FileName = res.FileName;
                convertedImage.Content = res.Content;
                convertedImage.ImageId = this.FODImageId;
                convertedImage.ImageAsString = res.ImageAsString;
                convertedImage.Action = 'ADD';
                convertedImage.ImageBodyId = -(this.Images.length + 1);
                convertedImage.Description = this.fodItemReportForm.get('ImageDescription')?.value;
                this.Images.push(convertedImage);
              }

              // reset Image description to empty string
              this.fodItemReportForm.get('ImageDescription')?.setValue('');

              let obj = {
                formControlName: 'Images',
                formControlValue: this.Images
              }

              this.onUpdateFODItem(obj);
            },
            (err) => {
              console.log(err);
              alert('Error while uploading image.');
            }
          )
      }
    }

    this.fileUploadCount = 0;
  }

  onSelectHandler(event: any, input: string) {
    if (input === 'ObjectTypeID') {
      let choiceText = event.target.options[event.target.options.selectedIndex].text;
      if (choiceText === 'Other') {
        this.hideSpecifyOther = false;
      } else {
        this.hideSpecifyOther = true;
      }
    }

    let obj = {
      formControlName: input,
      formControlValue: this.fodItemReportForm.get(input)?.value
    };

    this.onUpdateFODItem(obj);
  }

  onChangeTextHandler(event: any, input: string) {
    let obj = {
      formControlName: input,
      formControlValue: this.fodItemReportForm.get(input)?.value
    };

    this.onUpdateFODItem(obj);
  }

  initFODItemForm() {
    this.fodItemReportForm = this.fb.group({
      FODID: [0],
      ReportID: [0],
      LocationID: [0],
      SizeID: [0],
      ObjectTypeID: [0],
      ObjectTypeOtherText: [''],
      DesignatorID: [0],
      DesignatorDescription: [''],
      Remark: [''],
      Images: [],
      ImageDescription: [''],
      LocationCode: '',
      SizeCode: '',
      ObjectTypeCode: '',
      DesignatorCode: ''
    })
  }

  populateFodItemInputs(applyDefaultSelections: boolean) {
    let fodItemLocation$ = this.reportService.getFodItemLocations();
    let fodItemObjectSizes$ = this.reportService.getFodItemObjectSizes();
    let fodItemDescription$ = this.reportService.getFodItemObjectTypes();

    const all = zip(
      fodItemLocation$,
      fodItemObjectSizes$,
      fodItemDescription$
    );

    this.spinnerService.show();

    all.subscribe(
      (resultsArr) => {
        let responseFodItemLocation = resultsArr[0];
        let responseFodItemObjectSizes = resultsArr[1];
        let responseFodItemDescription = resultsArr[2];

        if (responseFodItemLocation.Success && responseFodItemLocation.Total > 0) {
          this.fodItemLocationArr = responseFodItemLocation.Result;
          if (applyDefaultSelections) {
            this.fodItemReportForm.get('LocationID')?.setValue(2);

            let obj = {
              formControlName: 'LocationID',
              formControlValue: 2
            }

            this.onUpdateFODItem(obj);
          }

        }

        if (responseFodItemObjectSizes.Success && responseFodItemObjectSizes.Total > 0) {
          this.fodItemObjectSizesArr = responseFodItemObjectSizes.Result;

          if (applyDefaultSelections) {
            this.fodItemReportForm.get('SizeID')?.setValue(3);

            let obj = {
              formControlName: 'SizeID',
              formControlValue: 3
            }

            this.onUpdateFODItem(obj);
          }

        }

        if (responseFodItemDescription.Success && responseFodItemDescription.Total > 0) {
          this.fodItemDescriptionArr = responseFodItemDescription.Result;

          if (applyDefaultSelections) {
            this.fodItemReportForm.get('ObjectTypeID')?.setValue(3);


            let obj = {
              formControlName: 'ObjectTypeID',
              formControlValue: 3
            }

            this.onUpdateFODItem(obj);
          }

        }

        this.spinnerService.hide();
      },
      (err) => {
        console.log('FOD ITEM COMPONENT ERROR', err);
        this.spinnerService.hide();
      }
    )
  }


  onDeleteFodItem() {
    this.onDelete.emit();
  }

  onUpdateFODItem(value: any) {
    this.onUpdate.emit(value);
  }

}
