/// <reference types="@angular/localize" />

/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from "ag-grid-enterprise";
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}


//LicenseManager.setLicenseKey("CompanyName=Enterprise Engineering Services,LicensedApplication=FAA Airport Safety Database,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017150,ExpiryDate=7_July_2022_[v2]_MTY1NzE0ODQwMDAwMA==2a0099570232b8dbe892c2237341dc7a");
//LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-043630 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Enterprise Engineering Services )_is_granted_a_( Multiple Applications )_Developer_License_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 2 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 13 June 2024 )____[v2]_MTcxODIzMzIwMDAwMA==4b9578e2d01a668bd3a3398f2d454be9");
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-058334}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Enterprise_Engineering_Services}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_June_2025}____[v3]_[01]_MTc0OTc2OTIwMDAwMA==3dc90821229b60f65725374e09c3b050");
platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));

//export { renderModule, renderModuleFactory } from '@angular/platform-server';
