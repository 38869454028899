<mat-card>
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <hr />
    <div class="card">
      <div class="card-header primary-header">Search Criteria</div>
      <div class="card-body">
        <form [formGroup]="searchReportForm">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="airportname">Airport Name:</label>
            <div class="col-sm-4">
              <div style="display: none">
                <input formControlName="ARPID" type="text" />
              </div>
              <input type="text"
                     class="form-control"
                     id="airportname"
                     #airportname
                     [value]="selectedAirportName"
                     (input)="onChangeAirport($event)"
                     [ngbTypeahead]="searchAirport"
                     [editable]="false"
                     (selectItem)="onSelectedAirport($event)" />
            </div>
            <label class="col-sm-2 col-form-label" for="foditemlocation">Location:</label>
            <div class="col-sm-4">
              <select class="form-control"
                      id="foditemlocation"
                      formControlName="LocationCode"
                      (change)="onChangeHandler($event, 'LocationCode')">
                <option *ngFor="let choice of fodItemLocationArr; let i = index"
                        [value]="fodItemLocationArr[i].LocationCode">
                  {{ fodItemLocationArr[i].LocationDesc }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="fodcollectiontype">Collection Type:</label>
            <div class="col-sm-4">
              <select class="form-control"
                      id="fodcollectiontype"
                      formControlName="CollectionType"
                      (change)="onChangeFodType($event)">
                <option *ngFor="let choice of fodTypeChoices; let i = index"
                        [value]="fodTypeChoices[i].CollectionType">
                  {{ fodTypeChoices[i].CollectionDesc }}
                </option>
              </select>
            </div>
            <label class="col-sm-2 col-form-label" for="foditemdescription">Description:</label>
            <div class="col-sm-4">
              <select class="form-control"
                      id="foditemdescription"
                      formControlName="ObjectCode"
                      (change)="onChangeHandler($event, 'ObjectCode')">
                <option *ngFor="let choice of fodItemDescriptionArr; let i = index"
                        [value]="fodItemDescriptionArr[i].ObjectCode">
                  {{ fodItemDescriptionArr[i].ObjectDesc }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="detectiontype">Detection Type:</label>
            <div class="col-sm-4">
              <select formControlName="DetectionCode"
                      class="form-control"
                      id="detectiontype"
                      (change)="onChangeHandler($event, 'DetectionCode')"
                      #detectiontype>
                <option *ngFor="
                    let choice of filteredDetectionTypeChoices;
                    let i = index
                  "
                        [value]="filteredDetectionTypeChoices[i].DetectionCode">
                  {{ filteredDetectionTypeChoices[i].DetectionDesc }}
                </option>
              </select>
            </div>

            <label class="col-sm-2 col-form-label" for="foditemsize">Size:</label>
            <div class="col-sm-4">
              <select class="form-control"
                      id="foditemsize"
                      formControlName="SizeCode"
                      (change)="onChangeHandler($event, 'SizeCode')">
                <option *ngFor="let choice of fodItemObjectSizesArr; let i = index"
                        [value]="fodItemObjectSizesArr[i].SizeCode">
                  {{ fodItemObjectSizesArr[i].SizeDesc }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label" for="fodfromdate">From Date:</label>
            <div class="col-sm-4">
              <div class="form-group">
                <div class="input-group">
                  <div style="display: none">
                    <input type="text" formControlName="DateFrom" />
                  </div>
                  <input type="text"
                         class="form-control"
                         format="mm/dd/yyyy"
                         [minDate]="{ year: 1900, month: 1, day: 1 }"
                         placeholder="yyyy-mm-dd"
                         ngbDatepicker
                         #d="ngbDatepicker"
                         id="fodfromdate"
                         #fodfromdate
                         [markDisabled]="markDisabledFromDate"
                         [maxDate]="maxDate"
                         (dateSelect)="onSelectDate($event, 'DateFrom')"
                         (change)="onChangeHandler($event, 'DateFrom')" />
                  <div class="input-group-append">
                    <button class="btn btn-sm btn-outline-secondary calendar"
                            type="button"
                            autofocus
                            (click)="onClickCalendar(d)">
                      <mat-icon>calendar_today</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <label class="col-sm-2 col-form-label" for="fodtodate">To Date:</label>
            <div class="col-sm-4">
              <div class="form-group">
                <div class="input-group">
                  <div style="display: none">
                    <input type="text" formControlName="DateTo" />
                  </div>
                  <input type="text"
                         class="form-control"
                         format="mm/dd/yyyy"
                         [minDate]="{ year: 1900, month: 1, day: 1 }"
                         [maxDate]="maxDate"
                         placeholder="yyyy-mm-dd"
                         ngbDatepicker
                         #d2="ngbDatepicker"
                         id="fodtodate"
                         #fodtodate
                         [markDisabled]="markDisabledToDate"
                         (dateSelect)="onSelectDate($event, 'DateTo')"
                         (change)="onChangeHandler($event, 'DateTo')" />
                  <div class="input-group-append">
                    <button class="btn btn-sm btn-outline-secondary calendar"
                            type="button"
                            autofocus
                            (click)="onClickCalendar(d2)">
                      <mat-icon>calendar_today</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer text-right">
        <div>
          <button type="submit"
                  class="btn btn-primary"
                  (click)="onSearch()"
                  [disabled]="
              !searchReportForm.valid ||
              searchReportForm.errors?.controlsHaveNoValue
            ">
            Search
          </button>

          <button type="button"
                  class="btn btn-outline-secondary"
                  (click)="onReset()">
            Reset
          </button>
        </div>
      </div>
    </div>

    <ngb-alert [dismissible]="false" [type]="alertType" *ngIf="message">
      {{ message }}
    </ngb-alert>
  </mat-card-content>

  <!-- <ng-container *ngIf="FODReportsResultsArr.length > 0">
    <div class="card">
      <div class="card-header primary-header">Search Results</div>
      <div class="card-body">
        <app-datatable
          [data]="FODReportsResultsArr"
          [displayedCols]="FODReportsTabledisplayedColumns"
        ></app-datatable>
      </div>
    </div>
  </ng-container> -->
  <!--Testing Reusable DataTable-->
  <ng-container *ngIf="FODReportsResultsArr.length > 0">
    <div class="card">
      <div class="card-header primary-header">Search Results</div>
      <div class="card-body">
        <app-datatable [tableData]="FODReportsResultsArr"
                       [tableColumns]="FODReportsTabledisplayedColumns"
                       [isPageable]="true"
                       [paginationSizes]="[5, 10, 15]"
                       [defaultPageSize]="10"
                       [rowActionIcon]="'fa fa-eye'"
                       [rowActionTitle]="'View'"
                       (rowAction)="viewFODReport($event)"
                       (sort)="sortTableData($event)"></app-datatable>
      </div>
    </div>
  </ng-container>
</mat-card>
