import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-submitreport',
  templateUrl: './submitreport.component.html',
  styleUrl: './submitreport.component.css'
})
export class SubmitreportComponent implements OnInit {

  fetchedHtml: any = "";
  title: string = "Submit a FOD Report";

  reportMode: string = "submitareport";

  constructor() { }



  ngOnInit(): void {
  }


}

