import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { PasswordRuleType } from "../model/shared";
import { FAALocalStorageService } from "./localStorage.service";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient, private authenticationService: AuthorizeService, private localStorageService: FAALocalStorageService) {

  }
  public fileExists(url: string): Observable<any> {
    return this.http.get(url);
  }
  public toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }

    return parts.join('&');
  }
  validatePasswordRule(ruleType, value) {
    const validNumbers = /\d/;
    const validUpperCase = /[A-Z]/;
    const validLowerCase = /[a-z]/;
    const validSpecialCharacter = /[!@#$ %^&* ()_ +\-=\[\]{ };':"\\|,.<>\/?]/;
    if (ruleType === PasswordRuleType.Number) {

      if (value && (validNumbers.test(value)))
        return true;
      else
        return false;
    }
    if (ruleType === PasswordRuleType.UpperCase) {

      if (value && (validUpperCase.test(value)))
        return true;
      else
        return false;
    }
    if (ruleType === PasswordRuleType.LowerCase) {

      if (value && (validLowerCase.test(value)))
        return true;
      else
        return false;
    }

    if (ruleType === PasswordRuleType.SpecialCharacter) {

      if (value && (validSpecialCharacter.test(value)))
        return true;
      else
        return false;
    }

    return null;


  }
}
