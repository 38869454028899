
<div class="" style="padding-left:20px; padding-right:20px;">
  <p></p>
  <mat-card class="asd-card">
    <mat-card-content>
     
        <div class="row">
          <div class="col-md-6">
            Contact Us: <i class="fas fa-envelope"></i>
            <a style="color: #007bff !important;" href="mailto: {{projectManagerEmail}} "> {{projectManager}}</a>
          </div>
          <div class="col-md-6 text-end">
            Version: {{version}}

          </div>
        </div>
    
    </mat-card-content>
  </mat-card>
  <p></p>
</div>

<div class="faa-header primary-dk-color" [style.background]="isDevelopmentEnv ? 'orange !important': '' ">
  <div class="footer">
    <div class="header-color" style="border-top: 10px solid #937206; background-color: #003E7E !important;color:#003E7E !important;">
    </div>
  </div>

  <div class="container" style="margin-top: 10px; font-size: 12px;">
    <div class="row">
      <div class="col-md-4">
        <div class="float-left">
          <img src="assets/logoDOT.png" class="float-left" style="margin-right: 10px;" width="84" height="84" alt="DOT seal" />
        </div>
        <address>
          <div style="padding-left:20px;">
            <a href="http://www.dot.gov/" title="Department of Transportation">U.S.&nbsp;Department&nbsp;of&nbsp;Transportation</a><br />
            Federal&nbsp;Aviation&nbsp;Administration<br />
            800&nbsp;Independence&nbsp;Avenue,&nbsp;SW<br />
            Washington,&nbsp;DC&nbsp;20591<br />
            1-866-TELL-FAA&nbsp;(1-866-835-5322)<br />
          </div>
        </address>


      </div>
      <div class="col-md-2">
        <address>
          <b>Web Policies</b>
          <ul class="list-unstyled">
            <li><a href="http://www.faa.gov/web_policies/">Web Policies &amp; Notices</a></li>
            <li><a href="http://www.faa.gov/privacy/">Privacy Policy</a></li>
          </ul>
        </address>
      </div>
      <div class="col-md-2">
        <address>
          <b>Government Sites</b>
          <ul class="list-unstyled">
            <li><a href="http://www.dot.gov/">DOT.gov</a></li>
            <li><a href="http://www.usa.gov/">USA.gov</a></li>
            <li><a href="http://www.plainlanguage.gov/">Plainlanguage.gov</a></li>
            <li><a href="http://www.recovery.gov/">Recovery.gov</a></li>
            <li><a href="http://www.regulations.gov/">Regulations.gov</a></li>
            <li><a href="http://www.data.gov/">Data.gov</a></li>
          </ul>
        </address>
      </div>
      <div class="col-md-4">
        <address>
          <b>Frequently Asked Questions</b>
          <ul class="list-unstyled">
            <li><a href="http://faa.custhelp.com/cgi-bin/faa.cfg/php/enduser/std_alp.php?p_sid=uLVYT9Rj">All Questions</a></li>
          </ul>
          <b>Contact Us</b>
          <ul class="list-unstyled">
            <li><a href="http://www.faa.gov/contact/">Contact FAA</a></li>
            <li><a href="http://www.oig.dot.gov/Hotline">Office of Inspector General (<abbr title="Office of Inspector General">OIG</abbr>) Hotline</a></li>
            <li><a href="http://www.faa.gov/foia/">Freedom of Information Act (<abbr title="Freedom of Information Act">FOIA</abbr>)</a></li>
          </ul>
        </address>
      </div>
    </div>
  </div>
</div>

