<ng-template #popContent>
  <div class="popup">
    <b>Single/Multiple Items:</b> FOD collected by an airport personnel and can
    be individually categorized<br />
    <b>Bulk Collection:</b> FOD collected by a FOD collection device in bulk
    amount and cannot by individually categorized
  </div>
</ng-template>

<ng-template #airportnamePopUp>
  <div class="popup">
    Enter the 3- or 4-letter ICAO code of the airport. (Example: L66 or KORD.
    The airport name will automatically be filled in if this is the first FOD
    submission.)
  </div>
</ng-template>

<ng-template #timeframePopUp>
  <div class="popup">
    Enter the time of the FOD collection in local time (not Zulu or GMT).
  </div>
</ng-template>

<ng-template #datefodPopUp>
  <div class="popup">Enter the day FOD collection occured.</div>
</ng-template>

<ng-template #detectiontypePopUp>
  <div class="popup">
    Select the method used to detect/collect FOD. Note, selection differs based
    report type (bulk versus single/multiple)
  </div>
</ng-template>

<ng-template #namePopUp>
  <div class="popup">
    Name of person filling out form and their Title. (Example: Sandy Robins,
    Wildlife Biologist). If you can be contacted for questions regarding the
    report please include a phone number or e-mail address.
  </div>
</ng-template>

<form [formGroup]="baseReportForm" (ngSubmit)="onSaveOrUpdateReport()">
  <!--Submit a Report-->
  <div class="form-group mat-card-header-text"
       *ngIf="reportMode === 'submitareport'">
    <div class="row">
      <label class="col-sm-6 col-form-label" for="submitreportfodtype">
        What kind of Foreign Object Debris (FOD) would you like to report?
        <span>
          <a href="javascript:void(0)"
             tabindex="-1"
             popoverTitle="Help"
             placement="bottom"
             triggers="mouseenter:mouseleave"
             data-html="true"
             [ngbPopover]="popContent"
             popoverClass="popup">
            <i class="fa fa-question-circle" aria-hidden="true"></i>
          </a>
        </span>
      </label>
     
      <div class="col-sm-6">
        <select class="form-control"
                id="submitreportfodtype"
                #submitreportfodtype
                (change)="onChangeFodType($event)"
                name="submitreportfodtype"
                formControlName="CollectionTypeCode">
          <option *ngFor="let choice of fodTypeChoices; let i = index"
                  [value]="fodTypeChoices[i].CollectionType">
            {{ fodTypeChoices[i].CollectionDesc }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <!--General Information-->
  <div class="card" id="generalinformation">
    <div class="card-header primary-header">General Information</div>
    <div class="card-body">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="airportname">
          <span class="text-danger">*</span>Airport Name
          <span>
            <a href="javascript:void(0)"
               tabindex="-1"
               popoverTitle="Help"
               placement="bottom"
               triggers="mouseenter:mouseleave"
               data-html="true"
               [ngbPopover]="airportnamePopUp">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </a>
          </span>:
        </label>
        <div class="col-sm-3">
          <div style="display: none">
            <input formControlName="AirportName" type="text" />
          </div>
          <input type="text"
                 class="form-control"
                 id="airportname"
                 #airportname
                 [ngbTypeahead]="searchAirport"
                 (selectItem)="onSelectedAirport($event)" />
        </div>

        <label class="col-sm-3 col-form-label" for="foddiscoverydate">
          <span class="text-danger">*</span>Date of FOD Discovery
          <span>
            <a href="javascript:void(0)"
               tabindex="-1"
               popoverTitle="Help"
               placement="bottom"
               triggers="mouseenter:mouseleave"
               data-html="true"
               [ngbPopover]="datefodPopUp">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </a>
          </span>:
        </label>
        <div class="col-sm-3">
          <div class="input-group">
            <div style="display: none">
              <input type="text" formControlName="Date" />
            </div>
            <input type="text"
                   class="form-control"
                   format="mm/dd/yyyy"
                   [minDate]="{ year: 1900, month: 1, day: 1 }"
                   readonly="readonly"
                   ngbDatepicker
                   #d="ngbDatepicker"
                   id="foddiscoverydate"
                   #foddiscoverydate
                   (dateSelect)="onSelectDate($event)" />
            <div class="input-group-append"
                 *ngIf="
                reportMode !== 'printreport' && reportMode !== 'viewreport'
              ">
              <button class="btn btn-sm btn-outline-secondary calendar"
                      type="button"
                      autofocus
                      (click)="onClickCalendar(d)">
                <mat-icon>calendar_today</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <label class="col-sm-3 col-form-label" for="timeframe">
          Timeframe
          <span>
            <a href="javascript:void(0)"
               tabindex="-1"
               popoverTitle="Help"
               placement="bottom"
               triggers="mouseenter:mouseleave"
               data-html="true"
               [ngbPopover]="timeframePopUp">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </a>
          </span>:
        </label>
        <div class="col-sm-3">
          <select formControlName="TimeFrameID"
                  class="form-control"
                  id="timeframe"
                  (change)="onSelectTimeFrame($event)">
            <option *ngFor="let choice of timeFrameArr; let i = index"
                    [value]="timeFrameArr[i].id">
              {{ timeFrameArr[i].name }}
            </option>
          </select>
        </div>

        <label class="col-sm-3 col-form-label" for="detectiontype">
          Detection Type
          <span>
            <a href="javascript:void(0)"
               tabindex="-1"
               popoverTitle="Help"
               placement="bottom"
               triggers="mouseenter:mouseleave"
               data-html="true"
               [ngbPopover]="detectiontypePopUp">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </a>
          </span>:
        </label>
        <div class="col-sm-3">
          <select formControlName="DetectionTypeCode"
                  class="form-control"
                  id="detectiontype"
                  #detectiontype>
            <option *ngFor="let choice of filteredDetectionTypeChoices; let i = index"
                    [value]="filteredDetectionTypeChoices[i].DetectionCode">
              {{ filteredDetectionTypeChoices[i].DetectionDesc }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <!--FOD Items section-->
  <div class="card" id="foditems">
    <div class="card-header primary-header">FOD Items</div>
    <div class="card-body">
      <app-foditem (onDelete)="deleteFodItem(i)"
                   (onUpdate)="handleFODItemUpdate(i, $event)"
                   *ngFor="let item of fodItemsArr; let i = index"
                   [currentFODItemIndex]="i"
                   [parentFODItemsarr]="fodItemsArr"
                   [FODItemReportMode]="reportMode"></app-foditem>
      <div class="card-body-center-items"
           *ngIf="reportMode !== 'printreport' && reportMode !== 'viewreport'">
        <button type="button" class="btn btn-primary" (click)="addFODItem()">
          Add FOD Item <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>

  <!--Report Information-->
  <div class="card" id="reportinformation">
    <div class="card-header primary-header">Report Information</div>
    <div class="card-body">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">
          Name
          <span>
            <a href="javascript:void(0)"
               tabindex="-1"
               popoverTitle="Help"
               placement="bottom"
               triggers="mouseenter:mouseleave"
               data-html="true"
               [ngbPopover]="namePopUp">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </a>
          </span>:
        </label>
        <div class="col-sm-3">
          <input formControlName="ReporterName"
                 type="text"
                 class="form-control" />
          <ngb-alert [dismissible]="false"
                     type="danger"
                     *ngIf="baseReportForm.get('ReporterName')?.errors?.pattern">
            Name can only contain letters and spaces
          </ngb-alert>
        </div>

        <label class="col-sm-3 col-form-label"> Title : </label>
        <div class="col-sm-3">
          <input formControlName="ReporterTitle"
                 type="text"
                 class="form-control" />
          <ngb-alert [dismissible]="false"
                     type="danger"
                     *ngIf="baseReportForm.get('ReporterTitle')?.errors?.pattern">
            Title can only contain letters and spaces
          </ngb-alert>
        </div>

        <label class="col-sm-3 col-form-label"> Email : </label>
        <div class="col-sm-3">
          <input formControlName="ReporterEmail"
                 type="text"
                 class="form-control" />
          <ngb-alert [dismissible]="false"
                     type="danger"
                     *ngIf="baseReportForm.get('ReporterEmail')?.errors?.email">
            Email address is invalid
          </ngb-alert>
        </div>

        <label class="col-sm-3 col-form-label"> Phone : </label>
        <div class="col-sm-3">
          <input formControlName="ReporterPhone"
                 type="text"
                 class="form-control"
                 mask="000-000-0000"
                 (focus)="onFocus($event, 'ReporterPhone')"
                 (blur)="onBlur($event, 'ReporterPhone')" />
          <ngb-alert [dismissible]="false"
                     type="danger"
                     *ngIf="
              baseReportForm.get('ReporterPhone')?.errors?.phonenumber
                ?.hasValidLength === false
            ">
            Phone numbers must have 10 digits
          </ngb-alert>
        </div>
      </div>
    </div>
    <div class="card-footer text-right"
         *ngIf="reportMode !== 'printreport' && reportMode !== 'viewreport'">
      <button type="submit"
              class="btn btn-primary"
              [disabled]="!baseReportForm.valid">
        {{ formButtonText }}
      </button>
    </div>
  </div>
</form>

<ngb-alert [dismissible]="false" type="danger" *ngIf="statusMessage">
  {{ statusMessage }}
</ngb-alert>
