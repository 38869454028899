<div class="content">
  <mat-card class="wildlife-card">
    <mat-card-header>
      <mat-card-title>
        <h1>{{ title }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-base-report [reportMode]="reportMode"></app-base-report>
    </mat-card-content>
  </mat-card>
</div>
