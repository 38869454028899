<h2 mat-dialog-title>{{title}}</h2>
<mat-divider></mat-divider>


<ng-template [ngIf]="type == 'PROFILE'  || type == 'EDIT_USER'  ">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="loginForm" class="full-width-form">
      <input matInput formControlName="userName" hidden>

      <mat-form-field class="full-width-field">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" type="text">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('firstName').hasError('required')">
        First Name is <strong>required</strong>
      </mat-error>

      <mat-form-field class="full-width-field">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" type="text">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('lastName').hasError('required')">
        Last Name is <strong>required</strong>
      </mat-error>

      <mat-form-field class="full-width-field">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email">
      </mat-form-field>
      <mat-hint class="font-italic">* Updating email will auto update Username</mat-hint>
      <mat-error *ngIf="loginForm.get('email').hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="loginForm.get('email').errors && loginForm.get('email').hasError('validEmail')">
        Valid Email is <strong>required</strong>
      </mat-error>


      <mat-form-field class="full-width-field">
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phoneNumber" type="tel">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('phoneNumber').hasError('phoneNumber')">
        Phone Number is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="loginForm.get('phoneNumber').errors && loginForm.get('phoneNumber').hasError('validPhone')">
        Valid Phone Number is <strong>required</strong>
      </mat-error>

      <ng-template [ngIf]="hasLoadedRoles &&  type == 'EDIT_USER'">
        <mat-form-field class="full-width-field">
          <mat-label>Role</mat-label>

          <mat-select formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role.name">
              {{role.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>


    </form>

  </mat-dialog-content>

  <mat-divider></mat-divider>
  <div class="alert" [ngClass]="alertType == 'success'  ? 'alert-success' : 'alert-danger' " style="margin-top: 5px; margin-bottom: 0px;" role="alert" *ngIf="message">
    <div [innerHTML]="message"></div>
  </div>
  <mat-dialog-actions align="end" class="full-width-field">

    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!loginForm.valid" (click)="onUpdateProfile()" cdkFocusInitial>Update</button>
  </mat-dialog-actions>
</ng-template>


<ng-template [ngIf]="type == 'NEW_USER'">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="loginForm" class="full-width-form">
      <input matInput formControlName="userName" hidden>

      <mat-form-field class="full-width-field">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" type="text">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('firstName').hasError('required')">
        First Name is <strong>required</strong>
      </mat-error>

      <mat-form-field class="full-width-field">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" type="text">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('lastName').hasError('required')">
        Last Name is <strong>required</strong>
      </mat-error>


      <mat-form-field class="full-width-field">
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phoneNumber" type="tel">
      </mat-form-field>
      <mat-error *ngIf="loginForm.get('phoneNumber').hasError('phoneNumber')">
        Phone Number is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="loginForm.get('phoneNumber').errors && loginForm.get('phoneNumber').hasError('validPhone')">
        Valid Phone Number is <strong>required</strong>
      </mat-error>

      <mat-form-field class="full-width-field">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email">
      </mat-form-field>
      <mat-hint class="font-italic">* Username will be the email</mat-hint>
      <mat-error *ngIf="loginForm.get('email').hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
      <mat-error *ngIf="loginForm.get('email').errors && loginForm.get('email').hasError('validEmail')">
        Valid Email is <strong>required</strong>
      </mat-error>



      <section class="user-login-info" >
        <!--<mat-form-field class="full-width-field">
          <mat-label>Username</mat-label>
          <mat-label>{{loginForm.get('email')}}</mat-label>
        </mat-form-field>-->

        <mat-form-field class="full-width-field">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" (blur)="message = '';">
        </mat-form-field>

        <div class="password-rule" style="font-size:smaller;">

          <label class="col" [ngClass]="loginForm.get('password').value === ''  ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="loginForm.get('password').value !== '' ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="loginForm.get('password').value === ''  ">highlight_off</mat-icon>
            Password is <strong>required</strong>
          </label>
          <label class="col" [ngClass]=" !validPasswordRule('UPPER', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('UPPER', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('UPPER', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>upper case</strong> letter
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('LOWER', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('LOWER', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('LOWER', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>lower case</strong> letter
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('SPECIAL', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('SPECIAL', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('SPECIAL', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>special</strong> character
          </label>

          <label class="col" [ngClass]=" !validPasswordRule('NUMBER', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('NUMBER', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('NUMBER', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must contain at least one <strong>numeric</strong> character
          </label>
          <label class="col" [ngClass]=" !validPasswordRule('MIN_LENGTH', loginForm.get('password').value) ? 'text-danger' :'text-success'">
            <mat-icon *ngIf="validPasswordRule('MIN_LENGTH', loginForm.get('password').value) ">check_circle_outline</mat-icon>
            <mat-icon *ngIf="!validPasswordRule('MIN_LENGTH', loginForm.get('password').value) ">highlight_off</mat-icon>
            Must be at least <strong>8 characters</strong>

          </label>
        </div>

        <mat-form-field class="full-width-field">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="newPassword">
        </mat-form-field>
        <mat-error *ngIf="loginForm.get('newPassword').hasError('required')">
          Confirm Password is <strong>required</strong>
        </mat-error>

        <div *ngIf="(loginForm.get('password').value !== '' && loginForm.get('newPassword').value !== '') && (loginForm.get('newPassword').value  !== loginForm.get('password').value)">

          <label class="col" [ngClass]=" loginForm.get('password').value  !== loginForm.get('newPassword').value ? 'text-danger' :'text-success'">
            <i [ngClass]="loginForm.get('password').value  !== loginForm.get('newPassword').value  ? 'fas fa-times-circle' :'far fa-check-circle'" aria-hidden="true"></i>
            Password did not <strong>matched</strong>
          </label>
        </div>
      </section>

      <ng-template [ngIf]="hasLoadedRoles">
        <mat-form-field class="full-width-field">
          <mat-label>Role</mat-label>

          <mat-select formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role.name">
              {{role.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>


    </form>

  </mat-dialog-content>

  <mat-divider></mat-divider>
  <div class="alert" [ngClass]="alertType == 'success'  ? 'alert-success' : 'alert-danger' " style="margin-top: 5px; margin-bottom: 0px;" role="alert" *ngIf="message">
    <div [innerHTML]="message"></div>
  </div>
  <mat-dialog-actions align="end" class="full-width-field">

    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" [disabled]="!loginForm.valid" (click)="onCreateUser()" cdkFocusInitial>Create</button>
  </mat-dialog-actions>
</ng-template>


