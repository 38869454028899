import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.css'
})
export class ConfirmationComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  title: string | null = 'FOD REPORT SUBMITTAL';
  reportNumber: string | null = '';
  reportID: string = '';

  ngOnInit(): void {
    this.reportNumber = this.route.snapshot.queryParams['reportNumber'];
    this.reportID = this.route.snapshot.queryParams['reportID'];
  }

  navigateTo(componentName: string) {
    this.router.navigate([`/${componentName}`], { queryParams: { reportNumber: this.reportNumber } });
  }

}

