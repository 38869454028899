import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Sort } from '@angular/material/sort';
import { ReportService } from '../services/report.service';
import { SpinnerService } from '../services/spinner.service';
import { Router } from '@angular/router';
import { SearchFODReportResult, TableColumn } from '../model/common';
import { environment } from '../../environments/environment';
import { OperatorFunction, Observable, zip } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { requireOneControl } from '../validators/requireOneControl.validator';
@Component({
  selector: 'app-searchreport',
  templateUrl: './searchreport.component.html',
  styleUrl: './searchreport.component.css'
})
export class SearchreportComponent implements OnInit {

  title: string = 'Search a Report';
  alertType: string = 'danger';
  message: string = '';
  searchReportForm: FormGroup;

  fodTypeChoices: any[] = [];

  detectionTypeChoices: any[] = [];
  filteredDetectionTypeChoices: any[] = [];

  airportsArr: any[] = [];
  airportsTypeaheadArr: any[] = [];

  fodItemLocationArr: any[] = [];
  fodItemObjectSizesArr: any[] = [];
  fodItemDescriptionArr: any[] = [];

  maxDate = this.calendar.getToday();
  selectedFromDate: NgbDate;
  selectedToDate: NgbDate;
  @ViewChild('fodfromdate') fodfromdateElementRef: ElementRef;
  @ViewChild('fodtodate') fodtodateElementRef: ElementRef;
  markDisabledFromDate: any;
  markDisabledToDate: any;

  selectedAirportName: string = '';
  FODReportsResultsArr: SearchFODReportResult[] = [];

  FODReportsTabledisplayedColumns: TableColumn[] = [{ name: 'Date', dataKey: 'DetectionDate', isSortable: true }, { name: 'Report Number', dataKey: 'ReportNumber', isSortable: true }, { name: 'Airport', dataKey: 'AIRPORT', isSortable: true }, { name: '# of FOD Items', dataKey: 'FODItemsCount', isSortable: true }];

  constructor(private fb: FormBuilder, public reportService: ReportService, private spinnerService: SpinnerService, private calendar: NgbCalendar, private router: Router) { }

  ngOnInit(): void {
    this.initSearchReportForm();
    this.initFODReportFilters();

    // dates disabled in FROM Date field are determined by TO Date
    this.markDisabledFromDate = (date: NgbDate, current?: { year: number, month: number }) => {

      if (this.selectedToDate) {
        return date.after(this.selectedToDate);
      }
      return false;
    }

    // dates disabled in To Date field are determined by FROM Date
    this.markDisabledToDate = (date: NgbDate, current?: { year: number, month: number }) => {
      if (this.selectedFromDate) {
        return date.before(this.selectedFromDate);
      }
      return false;
    }
  }

  viewFODReport(report: SearchFODReportResult) {
    //const link = this.router.serializeUrl(this.router.createUrlTree(['printreport'], { queryParams: { reportNumber: report.ReportNumber } }));
    // console.log('link', link)
    //window.open(link, '_blank'); // open page in a new tab like Legacy FOD App

    //  11-09-2021
    let link = `${environment.webUrl}printreport?reportNumber=${report.ReportNumber}`;
    window.open(link, '_blank'); // open page in a new tab like Legacy FOD App
  }

  sortTableData(sort: Sort) {
    let data = this.FODReportsResultsArr.slice();
    const keyName = sort.active;

    console.log(sort);

    if (!sort.active || sort.direction === '') {
      return;
    }

    if (sort.direction === 'asc') {
      data = this.FODReportsResultsArr.sort((a: any, b: any) => {
        if (typeof a[keyName] === 'string' && typeof b[keyName] === 'string') {
          return a[keyName].localeCompare(b[keyName]);
        } else {
          // values being compared are numbers (FOD ITEM COUNT)
          return a[keyName] - b[keyName];
        }
      });
    } else if (sort.direction === 'desc') {
      data = this.FODReportsResultsArr.sort((a: any, b: any) => {
        if (typeof a[keyName] === 'string' && typeof b[keyName] === 'string') {
          return b[keyName].localeCompare(a[keyName]);
        } else {
          // values being compared are numbers (FOD ITEM COUNT)
          return b[keyName] - a[keyName];
        }
      });
    }

    return data;
  }

  onChangeHandler(evt: any, id: string) {
    let value = evt.target.value;
    this.searchReportForm.get(id)?.setValue(value);
  }

  onReset() {
    console.log('onReset called')
    this.FODReportsResultsArr.splice(0, this.FODReportsResultsArr.length);
    this.searchReportForm.reset();
    this.selectedAirportName = '';

    this.fodfromdateElementRef.nativeElement.value = '';
    this.fodtodateElementRef.nativeElement.value = '';
  }

  onSearch() {
    console.log('onSearch() invoked')
    this.spinnerService.show();

    this.reportService.searchFODReports(this.searchReportForm.value)
      .subscribe(
        (res) => {
          console.log(res);
          if (res.Success && res.Total > 0) {
            this.FODReportsResultsArr = res.Result;
            this.message = '';

          } else {
            console.log('returned false')
            this.spinnerService.hide();
            this.FODReportsResultsArr.splice(0, this.FODReportsResultsArr.length);
            this.message = 'We cannot find any items based on your search criteria.';
          }
        },
        (err) => {
          console.log('on search error', err)
          this.spinnerService.hide();
        },
        () => {
          console.log('on search complete')
          this.spinnerService.hide();
        }
      );
  }

  onChangeAirport(evt: any) {
    console.log('onChangeAirport')
    console.log('evt', evt)

    if (evt.target.value.length === 0) {
      this.searchReportForm.get('ARPID')?.setValue(null);
    }
  }

  onSelectDate(evt: NgbDate, id: string) {

    console.log('onSelectDate called')
    // YYYY-MM-DD
    console.log('SEARC REPORT');
    let year = evt.year;
    let month = ('00' + evt.month).slice(-2);
    let day = ('00' + evt.day).slice(-2);
    let date = `${year}-${month}-${day}`;

    this.searchReportForm.get(id)?.setValue(date);

    /*
      this.fodfromdateElementRef.nativeElement.value = '';
    this.fodtodateElementRef.nativeElement.value = '';
    */
    if (id === 'DateFrom') {
      this.selectedFromDate = evt;
      this.fodfromdateElementRef.nativeElement.value = date;
    } else {
      this.selectedToDate = evt;
      this.fodtodateElementRef.nativeElement.value = date;
    }

    console.log('selectedToDate', this.selectedToDate)
    console.log('selectedFromDate', this.selectedFromDate)
  }

  onClickCalendar(dateObj: any) {
    dateObj.open();
  }

  onChangeFodType(evt: any) {
    this.populateDetectionTypeInput(evt.target.value);

    this.onChangeHandler(evt, 'CollectionType');
  }

  populateDetectionTypeInput(selectedFodType: string) {
    if (selectedFodType.length > 0) {
      this.filteredDetectionTypeChoices = this.detectionTypeChoices.filter((elem) => {
        if (selectedFodType === elem.CollectionType) {
          return true;
        }
        return false;
      });

      this.filteredDetectionTypeChoices.unshift({ DetectionCode: "", DetectionDesc: "" });
    } else {
      let detectionTypesArr: any[] = this.detectionTypeChoices.map((elem) => {
        let collectionType = elem.CollectionType;
        let objCopy = { ...elem };
        objCopy.DetectionDesc = `(${collectionType}) ${elem.DetectionDesc}`;
        return objCopy;
      });

      this.filteredDetectionTypeChoices = detectionTypesArr;
      this.filteredDetectionTypeChoices.unshift({ DetectionCode: "", DetectionDesc: "" });
      this.filteredDetectionTypeChoices.sort((elem1, elem2) => {
        const typeA = elem1.DetectionDesc.toUpperCase();
        const typeB = elem2.DetectionDesc.toUpperCase();

        let comparison = 0;

        if (typeA > typeB)
          comparison = 1;
        else if (typeA < typeB)
          comparison = -1;

        return comparison;
      });

    }

  }

  searchAirport: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.airportsTypeaheadArr.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onSelectedAirport(evt: NgbTypeaheadSelectItemEvent) {
    let selectedItem = evt.item;
    let rightParenIndex = selectedItem.indexOf(")");

    let ARPID = selectedItem.slice(1, rightParenIndex).trim();
    let airportName = selectedItem.slice(rightParenIndex + 1).trim();
    this.setAirportNameIDFormValues(ARPID, airportName);

    this.selectedAirportName = selectedItem;
  }

  setAirportNameIDFormValues(ARPID: string, airportName: string) {
    //this.searchReportForm.get('AirportName')?.patchValue(airportName);

    let airportObj = this.airportsArr.filter((elem) => {
      if (elem.ARPID === ARPID) {
        return true;
      }
      return false;
    })

    //this.searchReportForm.get('ARPID')?.patchValue(airportObj[0].Airport_ID);
    this.searchReportForm.get('ARPID')?.patchValue(airportObj[0].ARPID);
  }

  initSearchReportForm() {
    let formControlNamesArr = ['ARPID', 'CollectionType', 'DetectionCode', 'ObjectCode', 'LocationCode', 'SizeCode', 'DateFrom', 'DateTo'];

    this.searchReportForm = this.fb.group({
      ARPID: [],
      CollectionType: [],
      DetectionCode: [],
      ObjectCode: [],
      LocationCode: [],
      SizeCode: [],
      DateFrom: [],
      DateTo: []
    }, { validators: requireOneControl(formControlNamesArr) })
  }

  initFODReportFilters() {
    // FOD Report Filters
    let detectionTypes$ = this.reportService.getDetectionTypes();
    let airports$ = this.reportService.getAirports();
    let collectionTypes$ = this.reportService.getFODItemCollectionTypes();

    // FOD Item Filters
    let fodItemLocation$ = this.reportService.getFodItemLocations();
    let fodItemObjectSizes$ = this.reportService.getFodItemObjectSizes();
    let fodItemDescription$ = this.reportService.getFodItemObjectTypes();


    const all = zip(
      airports$,
      detectionTypes$,
      collectionTypes$,
      fodItemLocation$,
      fodItemObjectSizes$,
      fodItemDescription$
    );

    this.spinnerService.show();

    all.subscribe(
      (resultsArr) => {
        let responseAirport = resultsArr[0];
        let responseDetectionTypes = resultsArr[1];
        let responseCollectionTypes = resultsArr[2];

        let responseFodItemLocation = resultsArr[3];
        let responseFodItemObjectSizes = resultsArr[4];
        let responseFodItemDescription = resultsArr[5];

        if (responseAirport.Success && responseAirport.Total > 0) {
          this.airportsArr = responseAirport.Result;

          let resArr = [];
          resArr = responseAirport.Result;

          this.airportsTypeaheadArr = resArr.map((elem: any) => {
            let ARPID = elem.ARPID;
            let AirportName = elem.AirportName;

            let outputStr = `(${ARPID}) ${AirportName}`;

            return outputStr;
          });
        }

        if (responseDetectionTypes.Success && responseDetectionTypes.Total > 0) {
          this.detectionTypeChoices = responseDetectionTypes.Result;
          //this.detectionTypeChoices.unshift({ DetectionCode: "", DetectionDesc: "" });
          this.populateDetectionTypeInput("");
        }

        if (responseCollectionTypes.Success && responseCollectionTypes.Total > 0) {
          this.fodTypeChoices = responseCollectionTypes.Result;
          this.fodTypeChoices.unshift({ CollectionType: "", CollectionDesc: "" });
        }

        if (responseFodItemLocation.Success && responseFodItemLocation.Total > 0) {
          this.fodItemLocationArr = responseFodItemLocation.Result;
          // insert dummy No Select value
          this.fodItemLocationArr.unshift({ ID: "", LocationDesc: "" });
        }

        if (responseFodItemObjectSizes.Success && responseFodItemObjectSizes.Total > 0) {
          this.fodItemObjectSizesArr = responseFodItemObjectSizes.Result;
          this.fodItemObjectSizesArr.unshift({ ID: "", SizeDesc: "" });

        }

        if (responseFodItemDescription.Success && responseFodItemDescription.Total > 0) {
          this.fodItemDescriptionArr = responseFodItemDescription.Result;
          this.fodItemDescriptionArr.unshift({ ID: "", ObjectDesc: "" });
        }

        this.spinnerService.hide();

      },
      (err) => {
        console.log('Search Report Component ERROR:', err);
        this.spinnerService.hide();
      }
    )


  }

}

