import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxComponent } from './agGrid/mat-checkbox/mat-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSideToggleComponent } from './agGrid/mat-side-toggle/mat-side-toggle.component';
import { MatBtnCellRendererComponent } from './agGrid/mat-btn-cell-renderer/mat-btn-cell-renderer.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';

import { AgGridAngular } from 'ag-grid-angular';
/*import { AgGridAngular } from 'ag-grid-angular'; // AG Grid Component*/

import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { AgCharts } from 'ag-charts-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { UserComponent } from './admin/user/user.component';
import { UserDialog } from './admin/user/user-dialog.component';
import { LoginDialog } from '../api-authorization/login-menu/login-dialog.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';

import { ReportService } from './services/report.service';
import { AuthorizedAccessDialog } from './home/authorizeAccess-dialog.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { FAALocalStorageService } from './services/localStorage.service';
import { SpinnerService } from './services/spinner.service';
import { FaaMultiselectAutocompleteComponent } from './shared/faa-multiselect-autocomplete/faa-multiselect-autocomplete.component';
import { SharedService } from './services/shared.service';
import { AuthorizeGuard } from '../api-authorization/authorize.guard';
import { SearchreportComponent } from './searchreport/searchreport.component';
import { SubmitreportComponent } from './submitreport/submitreport.component';
import { EditreportComponent } from './editreport/editreport.component';
import { UpdateComponent } from './update/update.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PrintComponent } from './print/print.component';
import { BaseReportComponent } from './shared/base-report/base-report.component';
import { DatatableComponent } from './shared/datatable/datatable.component';
import { FoditemComponent } from './shared/foditem/foditem.component';
import { ModalComponent } from './shared/modal/modal.component';
import { DataPropertyGetterPipe } from './pipes/data-property-getter.pipe';


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LoginDialog, UserDialog, AuthorizedAccessDialog,
    UserComponent,
    MatCheckboxComponent,
    MatSideToggleComponent,
    MatBtnCellRendererComponent,
    FaaMultiselectAutocompleteComponent,

    HomeComponent, FooterComponent, HeaderComponent,
    SubmitreportComponent, EditreportComponent, UpdateComponent, ConfirmationComponent, PrintComponent, SearchreportComponent,
    BaseReportComponent,
    DatatableComponent,
    FoditemComponent,
    ModalComponent,
    DataPropertyGetterPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AgGridAngular, AgCharts,
   
    HttpClientModule,

    FormsModule, ReactiveFormsModule,
    ApiAuthorizationModule,

    MatDialogModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule, MatRadioModule,
    MatTooltipModule, MatIconModule, MatSlideToggleModule, MatCheckboxModule, MatSnackBarModule,
    MatCardModule, MatTableModule,
    MatExpansionModule, MatBadgeModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatChipsModule,
 
    SidebarModule, ButtonModule, DropdownModule, ToastModule, MultiSelectModule, ListboxModule, CheckboxModule, PaginatorModule,

    RouterModule.forRoot([

      { path: 'home', component: HomeComponent },
      { path: 'submitreport', component: SubmitreportComponent },
      { path: 'editreport', component: EditreportComponent },
      { path: 'searchreport', component: SearchreportComponent, canActivate: [AuthorizeGuard] }, // set up route guard
      { path: 'update', component: UpdateComponent },
      { path: 'confirmation', component: ConfirmationComponent },
      { path: 'printreport', component: PrintComponent },
      { path: 'manageUser', component: UserComponent, canActivate: [AuthorizeGuard] }, // set up route guard
      { path: '', redirectTo: 'home', pathMatch: 'full' }, // redirect to
      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '/404' }

    ], { onSameUrlNavigation: 'reload' }),
    BrowserAnimationsModule,
    NgbModule
    
  ],
  providers: [SharedService, ReportService, MessageService,
    FAALocalStorageService, SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
