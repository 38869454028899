import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function createPhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;


    if (!value) {
      return null;
    }

    const hasNumeric = /[0-9]+/.test(value);
    const hasValidLength = value.length === 10;

    const phoneNumberValid = hasNumeric && hasValidLength;

    let errorObject = {
      phonenumber: {
        hasNumeric,
        hasValidLength
      }
    };

    if (phoneNumberValid) {
      return null;
    }

    return errorObject;
  }
}
