import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function requireOneControl(arrOfControlNames: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;

    const hasValue = (element: string) => {
      return formGroup.get(element)?.value !== '' && formGroup.get(element)?.value !== null
    }

    if (arrOfControlNames.some(hasValue)) {
      return null;
    } else {
      return { controlsHaveNoValue: true }
    }

  }
}
