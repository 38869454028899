<mat-card>
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <hr />

    <p>Thank you for taking the time to report FOD to the FAA.</p>
    <p>
      <strong>Your FOD Report Confirmation Number is: </strong><span class="confirmation-number">{{ reportNumber }}</span>
    </p>
    <p>
      Click
      <a [routerLink]="['/printreport']" [queryParams]="{reportNumber}">here</a>
      for a printable version of this report
    </p>
    <hr />
    <h4>
      <strong>
        BY REPORTING THIS FOD, YOU ARE ACTIVELY CONTRIBUTING TO INCREASING AIR
        SAFETY.
      </strong>
    </h4>
  </mat-card-content>
</mat-card>
