<div class="content">
  <mat-card class="wildlife-card">
    <mat-card-header>
      <mat-card-title>
        <h1>{{ title }}</h1>
        <hr />
        <h1>Report for Confirmation Number: {{ reportNumber }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-base-report [reportMode]="reportMode"
                       [reportNumber]="reportNumber"
                       [foundFODReport]="foundFODReport"></app-base-report>
    </mat-card-content>
  </mat-card>
</div>
<ngb-alert [dismissible]="false" type="danger" *ngIf="statusMessage">
  {{ statusMessage }}
</ngb-alert>
