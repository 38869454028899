<ng-template #modal>

  <div class="modal-header" [ngClass]="
      modalConfig.modalHeaderClass === undefined
        ? 'modal-header'
        : modalConfig.modalHeaderClass
    ">
    <div class="col-10"><span class="modal-title h4 " id="modal-basic-title">{{ modalConfig.modalTitle }}</span></div>
    <div class="col-2 text-end">
      <button *ngIf="
      modalConfig.hideHeaderCloseButton === undefined ||
      !modalConfig.hideHeaderCloseButton()
    "
              type="button"
              class="btn btn-outline-primary close text-end"
              aria-label="Close"
              (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="modal-body"
       id="body"
       *ngIf="modalConfig.hideBody === undefined || !modalConfig.hideBody()">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer"
       id="footer"
       *ngIf="modalConfig.hideFooter === undefined || !modalConfig.hideFooter()">
    <button type="button"
            [ngClass]="
        modalConfig.buttonDismissClass === undefined
          ? 'btn btn-outline-secondary'
          : modalConfig.buttonDismissClass
      "
            (click)="dismiss()"
            *ngIf="
        modalConfig.hideDismissButton === undefined ||
        !modalConfig.hideDismissButton()
      "
            [disabled]="
        modalConfig.disableDismissButton !== undefined &&
        modalConfig.disableDismissButton()
      ">
      {{ modalConfig.dismissButtonLabel }}
    </button>
    <button type="button"
            [ngClass]="
        modalConfig.buttonCloseClass === undefined
          ? 'btn btn-outline-primary'
          : modalConfig.buttonCloseClass
      "
            (click)="close()"
            *ngIf="
        modalConfig.hideCloseButton === undefined ||
        !modalConfig.hideCloseButton()
      "
            [disabled]="
        modalConfig.disableCloseButton !== undefined &&
        modalConfig.disableCloseButton()
      ">
      {{ modalConfig.closeButtonLabel }}
    </button>
  </div>
</ng-template>
