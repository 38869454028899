import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuthorizeService } from "../../api-authorization/authorize.service";
import { FAALocalStorageService } from "./localStorage.service";
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ReportService {


  private readonly apiEndpoint = 'api/Service/';

  constructor(private http: HttpClient) { }

  // GET /GetAirports
  getAirports(): Observable<any> {
   // console.log('ReportService: getAirports() invoked')
    return this.http.get(`${this.apiEndpoint}GetAirports`);
  }

  // GET /GetDetectionTypes
  getDetectionTypes(): Observable<any> {
    //console.log('ReportService: getDetectionTypes() invoked')
    return this.http.get(`${this.apiEndpoint}GetDetectionTypes`);
  }

  // GET /GetLocations
  // returns Location choices for FOD Item
  getFodItemLocations(): Observable<any> {
    console.log('ReportService: getFodItemLocations() invoked')
    return this.http.get(`${this.apiEndpoint}GetLocations`);
  }

  // GET /GetObjectSizes
  // returns Sizes choices for FOD Item
  getFodItemObjectSizes(): Observable<any> {
    //console.log('ReportService: getFodItemObjectSizes() invoked')
    return this.http.get(`${this.apiEndpoint}GetObjectSizes`);
  }

  // GET /GetObjectTypes
  // returns Description choices for FOD Item
  getFodItemObjectTypes(): Observable<any> {
   // console.log('ReportService: getFodItemObjectTypes() invoked')
    return this.http.get(`${this.apiEndpoint}GetObjectTypes`);
  }

  // GET /GetReportByReportNumber
  // returns FOD Report
  getFODReportByReportNumber(reportNumber: string): Observable<any> {
   // console.log('ReportService: getFODReportByReportNumber() invoked')
    return this.http.get(`${this.apiEndpoint}GetReportByReportNumber?reportNumber=${reportNumber}`, {
      headers: new HttpHeaders(
        {
          'clientId': environment.clientId,
          'token': environment.token
        }
      )
    });

  }

  // GET /GetCollectionTypes
  getFODItemCollectionTypes(): Observable<any> {
    //console.log('ReportService: getFODItemCollectionTypes() invoked')
    return this.http.get(`${this.apiEndpoint}GetCollectionTypes`);
  }

  // POST /SearchFODReports
  searchFODReports(reportData: any): Observable<any> {
    console.log('ReportService: searchFODReports() invoked')
    return this.http.post(`${this.apiEndpoint}SearchFODReports`, reportData);
  }

  // POST /AddReport
  // Add New FOD Report
  addReport(reportData: any): Observable<any> {
    return this.http.post(`${this.apiEndpoint}AddReport`, reportData, {
      headers: new HttpHeaders(
        {
          'clientId': environment.clientId,
          'token': environment.token
        }
      )
    });
  }

  // POST /UpdateReport
  // Update existing FOD Report
  updateReport(reportData: any): Observable<any> {
    return this.http.post(`${this.apiEndpoint}UpdateReport`, reportData, {
      headers: new HttpHeaders(
        {
          'clientId': environment.clientId,
          'token': environment.token
        }
      )
    });
  }

  // POST /GetImageAsBytes
  //This method can only be used for Add New Report
  // Get Image As Bytes Array.
  //All uploaded Image(s) are stored in Image list; once Add Report is called, Image list with Content is loaded to the Database.
  getImageAsBytes(data: any): Observable<any> {
    //console.log('ReportService: getImageAsBytes() invoked')
    //console.log(data)
    return this.http.post(`${this.apiEndpoint}GetImageAsBytes`, data);

  }

}
