<h2 mat-dialog-title>{{title}}</h2>
<mat-divider></mat-divider>

  <mat-dialog-content class="mat-typography">
    <br />
    <p>You are not authorized to access this feature.</p>
    <p>Please contact <b>{{projectManager}}</b> by email <b>{{projectManagerEmail}}</b> and request for this feature.</p>

  </mat-dialog-content>

  <mat-divider></mat-divider>
  
  <mat-dialog-actions align="end" class="full-width-field">
      <button mat-raised-button mat-dialog-close>Close</button>
   </mat-dialog-actions>


